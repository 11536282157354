:root {
  --primary-3a: #fef5ec;
  --gray-dark: #262b2c;
  --white\<deleted\|variable-69f6a639\>: white;
  --gray-light: #e6e9ea;
  --white: white;
  --primary-2b: #b8fa76;
  --border-dark: rgba(38, 43, 44, 0.1);
  --primary-1c: #03464f;
  --primary-1a: #d3f8fd;
  --primary-1b: #50e4f8;
  --primary-2a: #edfedd;
  --primary-2c: #034f29;
  --primary-3b: #f8e250;
  --primary-3c: #492c0e;
  --white-faded: rgba(255, 255, 255, 0.1);
  --picknfix-blue: #0f61d6;
  --picknfix-blue-ligh: #64acff;
}

.w-users-userformpagewrap {
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  max-width: 340px;
  height: 100vh;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.w-users-userformheader {
  text-align: center;
}

.w-commerce-commercecheckoutformcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercelayoutcontainer {
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.w-commerce-commercelayoutmain {
  flex: 0 800px;
  margin-right: 20px;
}

.w-commerce-commercecartapplepaybutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  align-items: center;
  height: 38px;
  min-height: 30px;
  margin-bottom: 8px;
  padding: 0;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercecartapplepayicon {
  width: 100%;
  height: 50%;
  min-height: 20px;
}

.w-commerce-commercecartquickcheckoutbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #000;
  border-width: 0;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
  height: 38px;
  margin-bottom: 8px;
  padding: 0 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commercequickcheckoutgoogleicon,
.w-commerce-commercequickcheckoutmicrosofticon {
  margin-right: 8px;
  display: block;
}

.w-commerce-commercecheckoutcustomerinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutblockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 20px;
}

.w-commerce-commercecheckoutlabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutemailinput {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutemailinput::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutemailinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingaddresswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingfullname {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddress {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutrow {
  margin-left: -8px;
  margin-right: -8px;
  display: flex;
}

.w-commerce-commercecheckoutcolumn {
  flex: 1;
  padding-left: 8px;
  padding-right: 8px;
}

.w-commerce-commercecheckoutshippingcity {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingstateprovince {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingzippostalcode {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingcountryselector {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutshippingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutshippingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutshippingmethodswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutshippingmethodslist {
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
}

.w-commerce-commercecheckoutshippingmethoditem {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  flex-direction: row;
  align-items: baseline;
  margin-bottom: 0;
  padding: 16px;
  font-weight: 400;
  display: flex;
}

.w-commerce-commercecheckoutshippingmethoddescriptionblock {
  flex-direction: column;
  flex-grow: 1;
  margin-left: 12px;
  margin-right: 12px;
  display: flex;
}

.w-commerce-commerceboldtextblock {
  font-weight: 700;
}

.w-commerce-commercecheckoutshippingmethodsemptystate {
  text-align: center;
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  padding: 64px 16px;
}

.w-commerce-commercecheckoutpaymentinfowrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutcardnumber {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardnumber::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardnumber:focus,
.w-commerce-commercecheckoutcardnumber.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardexpirationdate {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardexpirationdate::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardexpirationdate:focus,
.w-commerce-commercecheckoutcardexpirationdate.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutcardsecuritycode {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  cursor: text;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutcardsecuritycode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutcardsecuritycode:focus,
.w-commerce-commercecheckoutcardsecuritycode.-wfp-focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingaddresstogglewrapper {
  flex-direction: row;
  display: flex;
}

.w-commerce-commercecheckoutbillingaddresstogglecheckbox {
  margin-top: 4px;
}

.w-commerce-commercecheckoutbillingaddresstogglelabel {
  margin-left: 8px;
  font-weight: 400;
}

.w-commerce-commercecheckoutbillingaddresswrapper {
  margin-top: 16px;
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutbillingfullname {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingfullname::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingfullname:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddress {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddress::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddress:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstreetaddressoptional:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcity {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcity::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcity:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingstateprovince {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingstateprovince::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingstateprovince:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingzippostalcode {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 16px;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingzippostalcode::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingzippostalcode:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutbillingcountryselector {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 100%;
  height: 38px;
  margin-bottom: 0;
  padding: 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commercecheckoutbillingcountryselector::placeholder {
  color: #999;
}

.w-commerce-commercecheckoutbillingcountryselector:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commercecheckoutorderitemswrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryblockheader {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  justify-content: space-between;
  align-items: baseline;
  padding: 4px 20px;
  display: flex;
}

.w-commerce-commercecheckoutorderitemslist {
  margin-bottom: -20px;
}

.w-commerce-commercecheckoutorderitem {
  margin-bottom: 20px;
  display: flex;
}

.w-commerce-commercecartitemimage {
  width: 60px;
  height: 0%;
}

.w-commerce-commercecheckoutorderitemdescriptionwrapper {
  flex-grow: 1;
  margin-left: 16px;
  margin-right: 16px;
}

.w-commerce-commercecheckoutorderitemquantitywrapper {
  white-space: pre-wrap;
  display: flex;
}

.w-commerce-commercecheckoutorderitemoptionlist {
  margin-bottom: 0;
  padding-left: 0;
  text-decoration: none;
  list-style-type: none;
}

.w-commerce-commercelayoutsidebar {
  flex: 0 0 320px;
  position: -webkit-sticky;
  position: sticky;
  top: 20px;
}

.w-commerce-commercecheckoutordersummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummarylineitem,
.w-commerce-commercecheckoutordersummaryextraitemslistitem {
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 8px;
  display: flex;
}

.w-commerce-commercecheckoutsummarytotal {
  font-weight: 700;
}

.w-commerce-commercecheckoutplaceorderbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  text-align: center;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 3px;
  align-items: center;
  margin-bottom: 20px;
  padding: 9px 15px;
  text-decoration: none;
  display: block;
}

.w-commerce-commercecheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-commerce-commerceaddtocartform {
  margin: 0 0 15px;
}

.w-commerce-commerceaddtocartoptionpillgroup {
  margin-bottom: 10px;
  display: flex;
}

.w-commerce-commerceaddtocartoptionpill {
  color: #000;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #000;
  margin-right: 10px;
  padding: 8px 15px;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-selected {
  color: #fff;
  background-color: #000;
}

.w-commerce-commerceaddtocartoptionpill.w--ecommerce-pill-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commerceaddtocartquantityinput {
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #fafafa;
  border: 1px solid #ddd;
  border-radius: 3px;
  width: 60px;
  height: 38px;
  margin-bottom: 10px;
  padding: 8px 6px 8px 12px;
  line-height: 20px;
  display: block;
}

.w-commerce-commerceaddtocartquantityinput::placeholder {
  color: #999;
}

.w-commerce-commerceaddtocartquantityinput:focus {
  border-color: #3898ec;
  outline-style: none;
}

.w-commerce-commerceaddtocartbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  padding: 9px 15px;
  text-decoration: none;
  display: flex;
}

.w-commerce-commerceaddtocartbutton.w--ecommerce-add-to-cart-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commercebuynowbutton {
  color: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: #3898ec;
  border-width: 0;
  border-radius: 0;
  align-items: center;
  margin-top: 10px;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.w-commerce-commercebuynowbutton.w--ecommerce-buy-now-disabled {
  color: #666;
  cursor: not-allowed;
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  outline-style: none;
}

.w-commerce-commerceaddtocartoutofstock {
  background-color: #ddd;
  margin-top: 10px;
  padding: 10px;
}

.w-commerce-commerceaddtocarterror {
  background-color: #ffdede;
  margin-top: 10px;
  padding: 10px;
}

.w-users-userloginformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-userformbutton {
  text-align: center;
  width: 100%;
}

.w-users-userformfooter {
  justify-content: space-between;
  margin-top: 12px;
  display: flex;
}

.w-users-userformerrorstate {
  margin-left: 20px;
  margin-right: 20px;
  position: absolute;
  top: 100%;
  left: 0%;
  right: 0%;
}

.w-commerce-commerceorderconfirmationcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercecheckoutcustomerinfosummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercecheckoutsummaryitem,
.w-commerce-commercecheckoutsummarylabel {
  margin-bottom: 8px;
}

.w-commerce-commercecheckoutsummaryflexboxdiv {
  flex-direction: row;
  justify-content: flex-start;
  display: flex;
}

.w-commerce-commercecheckoutsummarytextspacingondiv {
  margin-right: 0.33em;
}

.w-commerce-commercecheckoutshippingsummarywrapper,
.w-commerce-commercecheckoutpaymentsummarywrapper {
  margin-bottom: 20px;
}

.w-commerce-commercepaypalcheckoutformcontainer {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-commerce-commercepaypalcheckouterrorstate {
  background-color: #ffdede;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 10px 16px;
}

.w-users-userresetpasswordformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-userformsuccessstate {
  display: none;
}

.w-users-usersignupformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-checkbox {
  margin-bottom: 5px;
  padding-left: 20px;
  display: block;
}

.w-checkbox:before {
  content: ' ';
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox:after {
  content: ' ';
  clear: both;
  grid-area: 1 / 1 / 2 / 2;
  display: table;
}

.w-checkbox-input {
  float: left;
  margin: 4px 0 0 -20px;
  line-height: normal;
}

.w-checkbox-input--inputType-custom {
  border: 1px solid #ccc;
  border-radius: 2px;
  width: 12px;
  height: 12px;
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
  background-color: #3898ec;
  background-image: url('https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg');
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-color: #3898ec;
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
  box-shadow: 0 0 3px 1px #3898ec;
}

.w-users-usersignupverificationmessage {
  display: none;
}

.w-users-userupdatepasswordformwrapper {
  margin-bottom: 0;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.w-users-useraccountwrapper {
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  padding: 20px;
}

.w-users-blockcontent {
  background-color: #fff;
  border-bottom: 1px solid #e6e6e6;
  border-left: 1px solid #e6e6e6;
  border-right: 1px solid #e6e6e6;
  margin-bottom: 20px;
  padding: 20px;
}

.w-users-useraccountformsavebutton {
  text-align: center;
  margin-right: 8px;
}

.w-users-useraccountformcancelbutton {
  text-align: center;
  color: #333;
  background-color: #d3d3d3;
}

.w-users-useraccountsubscriptionlist {
  -webkit-overflow-scrolling: touch;
  flex: 1;
  overflow: auto;
}

.w-users-useraccountsubscriptionlistitem {
  align-items: flex-start;
  display: flex;
}

.w-commerce-commercecartiteminfo {
  flex-direction: column;
  flex: 1;
  margin-left: 16px;
  margin-right: 16px;
  display: flex;
}

.w-commerce-commercecartproductname {
  font-weight: 700;
}

.w-users-gridrow {
  grid-column-gap: 8px;
  grid-template-columns: auto auto;
  justify-content: flex-start;
  display: grid;
}

.w-users-flexcolumn {
  flex-direction: column;
  display: flex;
}

.w-users-useraccountsubscriptioncancelbutton {
  color: #3898ec;
  cursor: pointer;
  -webkit-appearance: none;
  -ms-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

@media screen and (max-width: 767px) {
  .w-commerce-commercelayoutcontainer {
    flex-direction: column;
    align-items: stretch;
  }

  .w-commerce-commercelayoutmain {
    flex-basis: auto;
    margin-right: 0;
  }

  .w-commerce-commercelayoutsidebar {
    flex-basis: auto;
  }
}

@media screen and (max-width: 479px) {
  .w-commerce-commercecheckoutemailinput,
  .w-commerce-commercecheckoutshippingfullname,
  .w-commerce-commercecheckoutshippingstreetaddress,
  .w-commerce-commercecheckoutshippingstreetaddressoptional {
    font-size: 16px;
  }

  .w-commerce-commercecheckoutrow {
    flex-direction: column;
  }

  .w-commerce-commercecheckoutshippingcity,
  .w-commerce-commercecheckoutshippingstateprovince,
  .w-commerce-commercecheckoutshippingzippostalcode,
  .w-commerce-commercecheckoutshippingcountryselector,
  .w-commerce-commercecheckoutcardnumber,
  .w-commerce-commercecheckoutcardexpirationdate,
  .w-commerce-commercecheckoutcardsecuritycode,
  .w-commerce-commercecheckoutbillingfullname,
  .w-commerce-commercecheckoutbillingstreetaddress,
  .w-commerce-commercecheckoutbillingstreetaddressoptional,
  .w-commerce-commercecheckoutbillingcity,
  .w-commerce-commercecheckoutbillingstateprovince,
  .w-commerce-commercecheckoutbillingzippostalcode,
  .w-commerce-commercecheckoutbillingcountryselector,
  .w-commerce-commerceaddtocartquantityinput {
    font-size: 16px;
  }
}

body {
  background-color: var(--primary-3a);
  color: var(--gray-dark);
  font-variation-settings: 'wght' 450;
  font-family: 'inherit';
  font-size: 16px;
  line-height: 1.4em;
}

h1 {
  font-variation-settings: 'wght' 700, 'wdth' 75;
  letter-spacing: -0.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 68px;
  font-weight: 700;
  line-height: 0.95em;
}

h2 {
  font-variation-settings: 'wght' 700, 'wdth' 75;
  letter-spacing: -0.01em;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 51px;
  font-weight: 700;
  line-height: 1em;
}

h3 {
  font-variation-settings: 'wght' 700, 'wdth' 75;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 38px;
  font-weight: 700;
  line-height: 1.1em;
}

h4 {
  font-variation-settings: 'wght' 700, 'wdth' 75;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 29px;
  font-weight: 700;
  line-height: 1.2em;
}

h5 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}

h6 {
  font-variation-settings: 'wght' 700, 'wdth' 75;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4em;
}

p {
  margin-bottom: 0;
}

a {
  color: var(--gray-dark);
  font-variation-settings: 'wght' 575;
  font-weight: 600;
  text-decoration: none;
}

ul {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 1.4em;
  display: flex;
}

ol {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}

label {
  margin-bottom: 5px;
  font-weight: bold;
  display: block;
}

strong {
  font-variation-settings: 'wght' 575;
  font-weight: 700;
}

blockquote {
  border-left: 5px solid #e2e2e2;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-size: 18px;
  line-height: 22px;
}

figcaption {
  text-align: center;
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.4em;
}

.loading-container {
  z-index: 9999;
  background-color: #fff;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.section {
  text-align: center;
  min-height: 90vh;
  padding-top: 2vh;
  width: 100%;
  margin: auto;
  /* overflow: hidden; */
}

.section.repair-section {
  /* height: fit-content; */
  margin-bottom: 60px;
  padding-block: 40px;
  min-height: unset;
}

.section.uber-section {
  /* height: fit-content; */
  padding-block: 5rem;
  margin-top: 0;
  background-color: #262b2c;
  color: white;
  min-height: unset;
  /* border-bottom: 2px solid #000; */
}

.section.fix-section {
  /* height: fit-content; */
  background: rgba(255, 254, 243, 1);
  display: flex;
  /* width: 100%; */
  justify-content: space-evenly;
  align-items: center;
  min-height: unset;
  /* padding: 10vh 15%; */
}

.section.faq {
  padding: 0;
  height: fit-content;
}

/* .section.fix-section img {
  border-radius: 52px;
  margin-top: -10vh;
} */

.section.fix-section .container---main .boxed-image-section {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.section.increased-bottom-padding {
  padding-bottom: 144px;
}

.section.first-section {
  padding-top: 48px;
}

.section.no-top-padding {
  padding-top: 0;
}

.container---main {
  text-align: left;
  width: 98%;
  margin: auto;
  font-family: inherit;
}

.style-guide {
  grid-row-gap: 112px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
}

.style-guide.changelog {
  grid-row-gap: 18px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.template-utility-section {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  grid-template-rows: auto;
  grid-template-columns: 0.33fr 1fr;
}

.style-guide-colors-wrapper {
  grid-row-gap: 36px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  display: flex;
}

.style-guide-color {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  flex: 1;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  display: flex;
  position: relative;
}

.bg-white {
  background-color: var(--white\<deleted\|variable-69f6a639\>);
}

.style-guide-section-contents {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  justify-items: start;
}

.style-guide-section-contents.form {
  justify-items: stretch;
}

.large-text {
  color: var(--gray-dark);
  text-align: left;
  font-family: inherit;
  font-size: 23px;
  line-height: 1.35em;
}

.large-text-2 {
  font-size: 18px;
}

.large-text.account-form-intro {
  text-align: center;
}

.large-text.text-bold {
  font-variation-settings: 'wght' 550;
}

.large-text.plan-description {
  flex: 1;
}

.small-text {
  text-align: left;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.4em;
}

.small-text.hint-text {
  margin-top: 6px;
}

.small-text.footer-copyright-text {
  opacity: 0.5;
}

.small-text.forgot-password-link {
  align-self: flex-end;
  margin-top: 6px;
  display: block;
}

.small-text.text-bold {
  text-align: center;
}

.small-text.remove-link {
  opacity: 0.5;
  transition: opacity 0.2s;
}

.small-text.remove-link:hover {
  opacity: 1;
}

.button {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  background-color: var(--gray-light);
  color: var(--gray-dark);
  font-variation-settings: 'wght' 575;
  text-align: center;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  padding: 18px 36px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button.plan-button {
  padding: 12px 20px;
}

.button.w--current {
  background-color: var(--gray-light);
}

.button.small {
  padding: 12px 24px;
}

.button.small.bg-gray-b {
  color: var(--white);
  background-color: #262b2c;
}

.button.small.bg-white {
  background-color: var(--white);
}

.button.small.bg-gray-a {
  background-color: var(--gray-light);
}

.button.small.form-button {
  background-color: var(--gray-light);
  align-self: flex-start;
}

.button.small.bg-primary-2c {
  background-color: var(--gray-dark);
}

.button.outlined {
  border: 1px solid var(--gray-dark);
  background-color: rgba(0, 0, 0, 0);
  transition: color 0.2s, background-color 0.2s;
}

.button.outlined:hover,
.button.bg-gray-dark {
  background-color: var(--gray-dark);
  color: var(--white);
}

.button.bg-gray-dark.text-primary-2b {
  color: var(--primary-2b);
}

.button.bg-primary-2b {
  background-color: #ffcb03;
}

.button.bg-primary-3b {
  background-color: var(--gray-light);
}

.button.bg-white {
  background-color: var(--gray-light);
  display: flex;
}

.form-block {
  margin-bottom: 0;
}

.form-block.subscribe-form-block {
  margin-bottom: 18px;
}

.form-input {
  border: 1px solid var(--border-dark);
  color: var(--gray-dark);
  border-radius: 6px;
  height: auto;
  margin-bottom: 0;
  padding: 16px 18px;
  font-size: 16px;
  line-height: 1.4em;
  transition: border-color 0.2s;
}

.form-input:focus,
.form-input.-wfp-focus {
  border-color: var(--gray-dark);
}

.form-input::placeholder {
  color: rgba(38, 43, 44, 0.5);
}

.form-input.text-area {
  min-height: 150px;
}

.form-input.checkout {
  background-color: var(--white);
  border-color: rgba(38, 43, 44, 0.25);
  min-height: 48px;
  margin-bottom: 12px;
  padding-top: 12px;
  padding-bottom: 12px;
}

.form-input.no-border {
  border-style: none;
}

.form-input.no-border::placeholder {
  color: rgba(38, 43, 44, 0.7);
}

.form-grid {
  grid-column-gap: 16px;
  grid-row-gap: 21px;
  grid-template-rows: auto;
  grid-template-columns: 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.selectable-field {
  align-items: center;
  margin-bottom: 0;
  padding-left: 0;
  display: flex;
}

.selectable-label {
  margin-bottom: 0;
  margin-left: 14px;
}

.footer {
  color: var(--white);
  background: rgba(38, 43, 44, 1);
  padding-top: 72px;
  padding-bottom: 22px;
  font-family: inherit;
}

.announcement-bar {
  background-color: var(--gray-dark);
  color: #fff;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.announcement-cross {
  opacity: 0.7;
  cursor: pointer;
  width: 20px;
  height: 20px;
  transition: opacity 0.2s;
  display: block;
}

.announcement-cross:hover {
  opacity: 1;
}

.announcement-contents {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  display: flex;
}

.trigger-search-modal {
  padding: 0;
}

.search-modal {
  z-index: 9999;
  justify-content: center;
  align-items: flex-start;
  display: none;
  position: fixed;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.search-modal-screen {
  background-color: rgba(38, 43, 44, 0.7);
  position: absolute;
  top: 0%;
  bottom: 0%;
  left: 0%;
  right: 0%;
}

.utility-page-wrap {
  justify-content: center;
  align-items: center;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  max-height: 100%;
  display: flex;
}

.utility-page-content {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  flex-direction: column;
  width: 100%;
  max-width: 547px;
  display: flex;
}

.utility-page-content.align-center {
  align-items: center;
}

.utility-page-form {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: stretch;
  padding-left: 18px;
  padding-right: 18px;
  display: flex;
}

.search-modal-container {
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  max-width: 684px;
  padding-top: 48px;
  display: flex;
  position: relative;
}

.select-wrapper {
  border: 1px solid var(--border-dark);
  background-color: var(--white);
  border-radius: 6px;
  overflow: hidden;
}

.select-field {
  color: var(--gray-dark);
  background-color: rgba(0, 0, 0, 0);
  background-image: url('/images/icon-select-arrow.svg');
  background-position: 89%;
  background-repeat: no-repeat;
  background-size: 16px 16px;
  border: 1px #000;
  width: 110%;
  height: auto;
  margin-bottom: 0;
  padding: 16px 18px;
  font-size: 16px;
  line-height: 1.4em;
}

.display-heading {
  text-align: left;
  letter-spacing: -0.02em;
  font-family: inherit;
  font-size: 87px;
  font-weight: 700;
  line-height: 0.95em;
}

.display-heading.text-center {
  font-size: 85px;
}

.display-heading.text-primary-3b {
  color: var(--gray-light);
}

.display-heading.hero-cta-heading {
  text-align: left;
  /* font-family: inherit;  */
}

.display-heading.text-primary-2b {
  color: var(--primary-2b);
}

.display-heading.text-primary-2b.hero-heading {
  text-align: center;
  color: #ffcb05;
}

.checkbox {
  border-color: var(--gray-dark);
  background-color: var(--white\<deleted\|variable-69f6a639\>);
  border-radius: 3px;
  flex: none;
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-left: 0;
}

.checkbox.w--redirected-checked {
  border-color: var(--gray-dark);
  background-color: var(--gray-dark);
  background-size: 12px 12px;
}

.radio {
  border-color: var(--gray-dark);
  background-color: #fff;
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-left: 0;
}

.radio.w--redirected-checked {
  border-width: 6px;
  border-color: var(--gray-dark);
}

.announcement-text {
  flex: 1;
  padding-top: 18px;
  padding-bottom: 18px;
}

.form-success {
  background-color: var(--primary-2b);
  border-radius: 6px;
}

.form-error {
  border-radius: 6px;
  margin-top: 12px;
  padding: 12px;
}

.menu-button {
  padding: 0;
}

.menu-button-wrapper {
  justify-content: center;
  width: 36px;
  height: 48px;
  display: flex;
  position: relative;
}

.menu-button-horziontal {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.menu-button-cross {
  opacity: 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
}

.menu-button-cross-line {
  background-color: #262626;
  width: 75%;
  height: 2px;
  position: absolute;
}

.menu-button-horizontal-line {
  background-color: #262626;
  width: 100%;
  height: 2px;
}

.navbar-wrapper {
  z-index: 99;
  background-color: var(--white);
  font-style: normal;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.navbar {
  background-color: rgba(0, 0, 0, 0);
  position: static;
}

.navbar-row {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  align-items: center;
  height: 82px;
  display: flex;
}

.changelog {
  grid-template-rows: auto;
  grid-template-columns: 0.33fr 1fr;
  align-items: center;
}

.photography-license-link {
  background-color: #000;
  border-radius: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.license-image {
  opacity: 0.5;
}

.license-text {
  z-index: 2;
  color: #fff;
  position: absolute;
}

.typography-license-grid {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  grid-template-rows: auto;
}

.typography-demo {
  font-variation-settings: 'wght' 700, 'wdth' 75;
  font-size: 96px;
  line-height: 1em;
}

.typography-demo.b {
  font-variation-settings: 'wght' 400, 'wdth' 100;
}

.iconography-grid {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  grid-auto-columns: auto;
  grid-auto-flow: row;
  justify-content: start;
}

.article {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  text-align: left;
  flex-direction: column;
  max-width: 666px;
  margin-bottom: -30px;
  display: flex;
}

.article h4,
.article h3 {
  margin-top: 12px;
}

.article.plan-article {
  margin-top: -30px;
}

.article ol {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  margin-bottom: 0;
  padding-left: 1.4em;
  display: flex;
}

.article blockquote {
  font-variation-settings: 'wght' 700, 'wdth' 75;
  border-left-style: none;
  margin-bottom: 0;
  padding: 24px 0;
  font-size: 38px;
  line-height: 1.1em;
}

.article img {
  border-radius: 18px;
}

.article.career-article {
  margin-top: -30px;
}

.heading-one {
  text-align: left;
  letter-spacing: -0.01em;
  /* margin-bottom: 4rem; */
  font-family: inherit;
  font-size: 68px;
  font-weight: 700;
  line-height: 0.95em;
}

.heading-one.text-center {
  font-family: inherit;
  font-size: 68px;
  font-weight: 700;
}

.heading-two {
  font-variation-settings: 'wght' 700, 'wdth' 75;
  letter-spacing: -0.01em;
  font-size: 51px;
  line-height: 1em;
}

.heading-two.large-quote-text {
  font-weight: 700;
}

.heading-two.text-primary-2b {
  color: var(--primary-2b);
}

.heading-two.feature-grid-heading {
  text-align: left;
  font-family: inherit;
  font-weight: 700;
}

.heading-three {
  color: var(--gray-dark);
  text-align: left;
  mix-blend-mode: multiply;
  background-color: rgba(0, 0, 0, 0);
  font-family: inherit;
  font-size: 25px;
  font-weight: 700;
  line-height: 1.1em;
}

.heading-three.widget-input {
  border: 1px solid var(--border-dark);
  padding: 3px 9px;
}

.heading-four {
  color: var(--gray-dark);
  text-align: left;
  font-family: inherit;
  font-size: 29px;
  font-weight: 700;
  line-height: 1.2em;
}

.heading-four.subscription-plan-heading {
  margin-bottom: 6px;
}

.heading-four.cart-heading {
  font-size: 24px;
}

.button-effect {
  z-index: 1;
  background-color: white;
  opacity: 0.05;
  border-radius: 200px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100%;
  right: 100%;
}

.protect-button {
  background-color: #ffffff;
  padding: 18px 36px 18px 36px;
  border-radius: 200px;
  border: 1px solid #000;
  color: #000000;
}

.button-effect.bg-white {
  background-color: #dbdbdb;
}

.button-effect.bg-gray-dark {
  background-color: var(--gray-dark);
}

.button-effect.bg-gray-dark.faded {
  opacity: 0.02;
}

.button-text {
  z-index: 2;
  text-align: center;
  font-family: inherit;
  position: relative;
}

.button-text.text-primary-1a {
  color: var(--primary-1a);
}

.button-text.text-primary-1b {
  color: var(--primary-3a);
  font-family: inherit;
}

.button-text.text-primary-2a {
  color: var(--gray-light);
}

.button-text.text-primary-3b {
  color: var(--primary-3a);
  -webkit-text-stroke-color: var(--gray-light);
}

.style-guide-description {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  display: flex;
}

.field-label {
  opacity: 0.7;
  font-variation-settings: 'wght' 575;
  margin-bottom: 6px;
  font-size: 14px;
  line-height: 1.4em;
}

.style-guide-colors {
  display: flex;
}

.style-guide-swatch {
  height: 120px;
}

.style-guide-swatch.bg-primary-3a {
  border: 1px solid var(--border-dark);
}

.text-bold {
  font-family: inherit;
  font-weight: 700;
}

.bg-primary-1a {
  background-color: var(--primary-1a);
}

.bg-primary-1b {
  background-color: var(--primary-1b);
}

.bg-primary-1c {
  background-color: var(--primary-1c);
}

.bg-primary-2a {
  background-color: var(--primary-2a);
}

.bg-primary-2b {
  background-color: var(--primary-2b);
}

.bg-primary-2c {
  background-color: var(--primary-2c);
}

.bg-primary-3a {
  background-color: var(--primary-3a);
}

.bg-primary-3b {
  background-color: var(--primary-3b);
}

.bg-primary-3c {
  background-color: var(--primary-3c);
}

.nav-link {
  color: var(--gray-dark);
  border-radius: 200px;
  padding: 3px 12px;
  transition: background-color 0.2s;
  display: block;
  position: static;
}

.nav-link:hover {
  background-color: var(--border-dark);
}

.nav-link.small-dropdown {
  position: relative;
}

.dropdown-toggle {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  color: var(--gray-dark);
  font-variation-settings: 'wght' 575;
  align-items: center;
  padding: 0;
  display: flex;
}

.nav-menu-cart {
  flex: 1;
  align-items: center;
  display: flex;
}

.nav-cart-button {
  padding: 3px 12px;
}

.cart-button {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  color: var(--gray-dark);
  background-color: rgba(0, 0, 0, 0);
  padding: 0;
}

.nav-cart-quantity {
  color: var(--gray-dark);
  background-color: rgba(0, 0, 0, 0);
  min-width: auto;
  height: auto;
  margin-left: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.4em;
}

.nav-cart-quantity-wrapper {
  align-items: center;
  display: flex;
}

.nav-right-contents {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  align-items: center;
  display: flex;
}

.additional-nav-link {
  font-family: inherit;
}

.nav-cart-mobile {
  display: none;
}

.navbar-logo {
  display: block;
}

.nav-menu {
  align-items: center;
  display: flex;
  position: static;
}

.mobile-logo,
.mobile-logo.w--current {
  display: none;
}

.nav-links-wrapper {
  align-items: center;
  display: flex;
}

.close-mobile-menu {
  z-index: 99999;
  opacity: 0;
  pointer-events: none;
  cursor: pointer;
  display: block;
  position: fixed;
  top: 36px;
  right: 36px;
}

.dropdown-wide {
  background-color: var(--white);
  transform: translate(0, 29px);
}

.dropdown-wide.w--open {
  left: 0;
  transform: translate(0, 29px);
}

.dropdown-wide-contents {
  border-top: 1px solid var(--border-dark);
  border-bottom: 1px solid var(--border-dark);
  padding: 24px 24px 36px;
}

.dropdown-wide-grid {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  grid-template-rows: auto;
  grid-template-columns: minmax(370px, 1fr) 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.dropdown-promo {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  background-color: var(--white);
  border-radius: 18px;
  flex-direction: column;
  padding: 18px;
  display: flex;
  position: static;
}

.dropdown-promo-image {
  border-radius: 9px;
  display: block;
}

.dropdown-sub-menu {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.dropdown-menu-link {
  transition: opacity 0.2s;
}

.dropdown-menu-link:hover {
  opacity: 0.7;
}

.footer-contents-wrapper {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.footer-grid {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.footer-menu {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.footer-link {
  font-weight: bold;
  opacity: 0.7;
  color: var(--white);
  transition: opacity 0.2s;
}

.footer-link:hover,
.footer-link.small {
  opacity: 1;
  text-decoration: underline;
}

.social-links {
  grid-column-gap: 14px;
  grid-row-gap: 14px;
  align-items: center;
  display: flex;
  /* margin-right: 40px; */
}

.social-links a {
  margin: 0 10px;
  transition: transform 0.3s ease;
}

.social-links a:hover {
  transform: scale(1.1);
}

.horizontal-line {
  background-color: var(--border-dark);
  width: 100%;
  height: 1px;
}

.horizontal-line.white {
  background-color: var(--white-faded);
}

.footer-bottom-contents {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.footer-cta {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.footer-buttons {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  display: flex;
}

.footer-copyright-social {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.social-links-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  align-items: center;
  display: flex;
}

.social-links-wrapper.compact {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
}

.social-link-icon {
  width: 18px;
  display: block;
}

.footer-legal-text {
  opacity: 0.5;
}

.footer-logo-image {
  display: block;
}

.dropdown-list {
  background-color: var(--white);
  border-radius: 18px;
  overflow: hidden;
  transform: translate(0, 12px);
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.1);
}

.dropdown-list.w--open {
  background-color: var(--white);
  border-radius: 18px;
  padding-top: 2px;
  padding-bottom: 2px;
  transform: translate(0, 12px);
  box-shadow: 0 2px 9px rgba(0, 0, 0, 0.2);
}

.dropdown-link {
  width: auto;
  padding: 12px 24px;
  transition: background-color 0.2s;
}

.dropdown-link:hover {
  background-color: #f3f3f3;
}

.dropdown-link.w--current {
  color: var(--gray-dark);
}

.search {
  background-color: var(--white);
  border-radius: 200px;
  align-items: center;
  width: 100%;
  margin-bottom: 0;
  padding: 6px;
  display: flex;
  box-shadow: 0 2px 18px rgba(0, 0, 0, 0.2);
}

.search-input {
  color: var(--gray-dark);
  background-color: rgba(0, 0, 0, 0);
  border: 1px #000;
  align-self: stretch;
  height: auto;
  margin-bottom: 0;
  padding: 0 0 0 24px;
  font-size: 16px;
}

.search-input::placeholder {
  color: var(--gray-dark);
}

.account-form-block {
  flex-direction: column;
  width: 100%;
  max-width: 547px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
}

.account-page-wrapper {
  align-items: center;
  max-width: none;
  height: auto;
  min-height: 75vh;
  padding: 144px 18px;
}

.form-box {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  background-color: var(--white);
  border: 1px #000;
  border-radius: 18px;
  flex-direction: column;
  margin-bottom: 0;
  padding: 48px 24px;
  display: flex;
}

.account-form-wrapper {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.account-form-contents {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-self: stretch;
  display: flex;
}

.form-footer {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  justify-content: center;
}

.form-footer.small-text {
  margin-top: -12px;
}

.form-input-group {
  flex-direction: column;
  display: flex;
}

.success-state {
  text-align: center;
}

.form-utility-contents {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.error-state {
  border-radius: 5px;
  margin-top: 24px;
  margin-left: 0;
  margin-right: 0;
  padding: 12px 18px;
  position: relative;
  top: 0%;
}

.form-header {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.text-center {
  text-align: center;
}

.user-account-wrapper {
  background-color: var(--primary-3a);
  min-height: auto;
  padding: 72px 0 144px;
}

.user-account-section {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  width: 100%;
  max-width: 904px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.account-button-group {
  align-items: center;
  display: flex;
}

.cancel-button {
  color: var(--gray-dark);
  font-variation-settings: 'wght' 575;
  background-color: rgba(0, 0, 0, 0);
}

.user-account-form {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.user-account-sections {
  grid-column-gap: 96px;
  grid-row-gap: 96px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.subscriptions-box {
  background-color: var(--white);
  border-radius: 18px;
  flex-direction: column;
  padding: 48px 24px;
  display: flex;
}

.subscription-plan-image {
  display: none;
}

.subscription-plan-details {
  margin-left: 0;
}

.subscription-price-wrapper {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: row;
  align-items: center;
}

.subscriptions-list {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.pricing-section {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.plan-grid {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.pricing-plan-1 {
  background-color: var(--white);
  border-radius: 18px;
  flex-direction: column;
  height: 100%;
  padding: 9px;
  display: flex;
}

.pricing-plan-1-header {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-radius: 12px;
  flex-direction: column;
  padding: 24px 18px;
  display: flex;
  position: relative;
}

.pricing-plan-1-body {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  flex: 1;
  padding: 24px;
  display: flex;
}

.plan-key-features-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.plan-key-features-list ul {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  padding-left: 1.4em;
  display: flex;
}

.plan-key-features-list li {
  border-bottom: 1px solid var(--border-dark);
  padding-bottom: 6px;
}

.pricing-plan-1-price-wrapper {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}

.pricing-plan-action-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.badge {
  background-color: var(--gray-light);
  border-radius: 200px;
  padding: 4px 12px;
}

.badge.plan-badge {
  position: absolute;
  top: 12px;
  right: 12px;
}

.badge.bg-primary-2c {
  background-color: var(--primary-1c);
  color: var(--primary-2a);
}

.badge.ai-notification-badge {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  background-color: #e0e0e0;
  align-items: center;
  padding-left: 4px;
  padding-right: 18px;
  display: flex;
}

.badge.link-badge {
  background-color: var(--border-dark);
  padding: 2px 10px;
  font-size: 13px;
  line-height: 1.4em;
}

.plans-reviews-summary {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.cart-container {
  border-radius: 18px;
}

.cart-header {
  border-bottom-style: none;
}

.apple-pay-button {
  border-radius: 9px;
  height: 48px;
}

.cart-item-description {
  margin-left: 0;
}

.cart-footer {
  border-top-style: none;
}

.close-cart-icon {
  display: block;
}

.close-cart-button {
  width: 24px;
  height: 24px;
}

.cart-quantity {
  background-color: var(--gray-light);
  border-style: none;
  margin-bottom: 0;
}

.product-details-box {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  background-color: var(--white);
  border-radius: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-self: stretch;
  min-height: 550px;
  padding: 12px;
  display: grid;
}

.product-details-title-wrapper {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  border-radius: 12px;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  display: flex;
}

.product-title {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.plan-subheading {
  max-width: 514px;
}

.product-features-price-wrapper {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  justify-content: space-between;
  padding: 12px 24px;
  display: flex;
}

.hide {
  display: none;
}

.purchasing-options-wrapper {
  flex-direction: column;
  margin-bottom: 0;
  display: flex;
}

.product-wrapper {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.app-store-rankings-section {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  text-align: center;
  align-items: center;
  display: flex;
}

.app-store-ranking {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.app-store-ranking.large {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
}

.service-icon-circle {
  background-color: var(--white);
  border-radius: 50%;
  padding: 12px;
}

.service-icon-circle.large {
  padding: 24px;
}

.service-icon-circle.large.bordered {
  border: 6px solid var(--picknfix-blue);
}

.service-icon-circle.large.bordered-light {
  border: 6px solid var(--primary-1a);
}

.service-icon-circle.border-primary-1b {
  border: 3px solid var(--primary-1b);
  flex: none;
}

.service-icon-circle.border-primary-1b.overlap-next {
  margin-right: -12px;
}

.service-icon-circle.border-primary-2b {
  border: 3px solid var(--primary-2b);
}

.service-icon-circle.border-primary-2b.overlap-next {
  margin-right: -12px;
}

.service-icon-circle.border-primary-3b {
  border: 3px solid var(--primary-3b);
}

.service-icon-circle.border-primary-3b.overlap-next {
  margin-right: -12px;
}

.stars img {
  width: 150px;
}

.app-store-divider {
  background-color: var(--gray-dark);
  opacity: 0.2;
  border-radius: 3px;
  width: 3px;
  height: 30px;
}

.checkout-form {
  background-color: var(--primary-3a);
  padding: 48px 0;
}

.block-content {
  border: 1px #000;
  border-bottom-right-radius: 9px;
  border-bottom-left-radius: 9px;
  padding: 0 24px 36px;
}

.block-header {
  border-style: none;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
  align-items: center;
  padding: 24px;
}

.checkout-form-contents {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  flex-direction: column;
  display: flex;
}

.order-item {
  align-items: center;
}

.muted {
  opacity: 0.5;
  font-family: inherit;
}

.blog-intro {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.blog-grid-halves {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.blog-list-item {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.blog-image {
  aspect-ratio: 3 / 2;
  object-fit: cover;
  width: 100%;
  transition: transform 0.2s;
  display: block;
}

.blog-image:hover {
  transform: scale(1.03);
}

.blog-list-title-wrapper {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  font-variation-settings: 'wght' 450;
  flex-direction: column;
  display: flex;
}

.post-meta {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  display: flex;
}

.blog-grid-thirds {
  grid-column-gap: 36px;
  grid-row-gap: 48px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.blog-image-link {
  border-radius: 18px;
  width: 100%;
  display: block;
  overflow: hidden;
}

.subscribe-section {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  background-color: var(--primary-2b);
  border-radius: 36px;
  align-items: center;
  padding: 72px;
  display: flex;
}

.container---m {
  width: 100%;
  max-width: 865px;
  padding-right: 200px;
  margin: 0;
}

.container---m.align-center {
  margin-left: 0;
  margin-right: auto;
}

.subscribe-contents-wrapper {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.subscribe-title {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.container---s {
  width: 100%;
  max-width: 547px;
}

.container---s.product-feature-desription-wrapper {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
}

.subscribe-form {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: stretch;
  display: flex;
}

.subscribe-graphic-wrapper {
  flex: 1;
  justify-content: center;
  display: flex;
}

.subscribe-graphic {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: var(--white);
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  flex-direction: column;
  width: 100%;
  max-width: 428px;
  padding: 18px;
  display: flex;
  position: relative;
  box-shadow: 0 -20px 20px rgba(0, 0, 0, 0.05);
}

.subscribe-graphic-text {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  display: flex;
}

.subscribe-graphic-scrim {
  background-image: linear-gradient(
    to bottom,
    rgba(184, 250, 118, 0),
    var(--primary-2b)
  );
  width: 100%;
  height: 35%;
  position: absolute;
  bottom: 0;
  left: 0;
}

.subscribe-graphic-image {
  aspect-ratio: 16 / 9;
  object-fit: cover;
  border-radius: 18px;
}

.container---l {
  width: 100%;
  max-width: 904px;
}

.container---l.align-center {
  margin-left: auto;
  margin-right: auto;
}

.blog-post-container {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.blog-post-title-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.blog-post-title {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.blog-post-summary-author {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.author-link {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  font-variation-settings: 'wght' 450;
  align-items: center;
  display: flex;
}

.blog-author-image {
  width: auto;
  max-height: 48px;
}

.blog-author-name-wrapper {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  align-items: center;
  display: flex;
}

.blog-main-image {
  aspect-ratio: 3 / 2;
  object-fit: cover;
  border-radius: 18px;
}

.blog-continue-wrapper {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.author-title {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  align-items: center;
  display: flex;
}

.author-image {
  width: auto;
  max-height: 96px;
}

.author-title-text {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.author-meta {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  display: flex;
}

.author-social-image {
  width: 18px;
  display: block;
}

.career-wrapper {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  display: flex;
}

.career-title-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.career-basis {
  align-items: center;
  display: flex;
}

.form {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: stretch;
  display: flex;
}

.form-pair {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.careers-box {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  background-color: var(--white);
  border-radius: 18px;
  flex-direction: column;
  padding: 36px;
  display: flex;
}

.careers-list {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  display: flex;
}

.career-list-item {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  border: 1px solid var(--border-dark);
  font-variation-settings: 'wght' 450;
  border-radius: 9px;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  transition: border-color 0.2s;
  display: flex;
}

.career-list-item:hover {
  border-color: var(--gray-dark);
}

.image-box {
  aspect-ratio: 2;
  text-align: center;
  border-radius: 36px;
  justify-content: center;
  align-items: center;
  padding: 48px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.image-box.bg-primary-3c {
  align-self: stretch;
}

.image-box.bg-gray-dark {
  background-color: var(--gray-dark);
}

.image-box-image {
  opacity: 0.6;
  mix-blend-mode: lighten;
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
}

.image-box-image.blend-mode-normal {
  opacity: 0.7;
  mix-blend-mode: normal;
}

.image-box-contents {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  align-items: center;
  display: flex;
  position: relative;
}

.image-box-contents.quote {
  text-align: left;
  flex: 1;
  justify-content: space-between;
  align-self: stretch;
  align-items: flex-start;
}

.career-intro {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.grid-halves {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: center;
  display: grid;
}

.career-text {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.rounded-image {
  border-radius: 18px;
}

.rounded-image.about-cluster-image {
  object-fit: cover;
}

.career-benefits {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.benefits-grid {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.benefit-item {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.benefit-icon-title {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.icon-circle {
  border: 6px solid var(--white);
  background-color: var(--gray-light);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  padding: 18px;
  display: flex;
}

.icon-circle.bg-primary-3b {
  background-color: var(--primary-3b);
}

.icon-circle.bg-primary-2b {
  background-color: var(--primary-2b);
}

.icon-circle.overlap-next-icon {
  margin-right: -18px;
}

.career-benefits-section {
  grid-column-gap: 96px;
  grid-row-gap: 96px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.career-benefits-cluster {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: center;
  align-self: stretch;
  align-items: flex-start;
  display: flex;
}

.container---xs {
  width: 100%;
  max-width: 428px;
}

.career-benefits-boxes {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.metric-box {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  background-color: var(--gray-light);
  color: var(--primary-3c);
  border-radius: 18px;
  flex-direction: column;
  padding: 36px;
  display: flex;
}

.metric-box.bg-primary-1b {
  background-color: var(--primary-1b);
  color: var(--primary-1c);
}

.metric-box.reduced-padding {
  padding: 24px;
}

.metric-text {
  color: var(--gray-dark);
  font-variation-settings: 'wght' 700, 'wdth' 75;
  font-size: 100px;
  line-height: 0.95em;
}

.career-benefits-image {
  object-fit: cover;
  border-radius: 18px;
  width: 150%;
  max-width: 428px;
  height: 300px;
}

.quote-box {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  background-color: var(--border-dark);
  color: var(--primary-2c);
  border-radius: 18px;
  flex-direction: column;
  padding: 36px;
  display: flex;
}

.quote-box.bg-primary-3b {
  background-color: var(--primary-3b);
  color: var(--primary-3c);
}

.quote-box-author {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  flex-direction: column;
  display: flex;
}

.about-intro-section {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.about-intro-text {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.about-cluster {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-rows: auto;
  grid-template-columns: minmax(200px, 428px) 1fr minmax(200px, 428px);
  grid-auto-columns: 1fr;
  display: grid;
}

.about-cluster-column {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  display: flex;
}

.large-quote {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.quote-author-image {
  width: auto;
  max-height: 72px;
}

.values-list {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  display: flex;
}

.values-list-item {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 0.4fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
}

.blog-summary-section {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  display: flex;
}

.blog-summary-grid {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.blog-summary-grid.thirds {
  grid-template-columns: 2fr 1fr;
}

.blog-summary-cta {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: var(--primary-2c);
  color: var(--primary-2a);
  border-radius: 18px;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  display: flex;
}

.large-cta-box {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  background-color: var(--primary-1a);
  text-align: center;
  border-radius: 36px;
  flex-direction: column;
  align-items: center;
  padding: 48px 72px 72px;
  display: flex;
}

.large-cta-box.even-padding {
  padding-top: 72px;
}

.large-cta-box-text {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.large-cta-box-text.align-left {
  align-items: flex-start;
}

.icon-circle-image {
  color: var(--picknfix-blue);
}

.contact-section {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.contact-section.align-left {
  align-items: flex-start;
}

.contact-title {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.contact-methods-section {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-self: stretch;
  display: grid;
}

.contact-box {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  background-color: var(--white);
  border-radius: 18px;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  display: flex;
}

.social-link-circle {
  border: 1px solid var(--gray-dark);
  border-radius: 50%;
  flex: none;
  justify-content: center;
  align-items: center;
  padding: 16px;
  display: flex;
}

.social-link-circle.bg-white {
  border-style: none;
}

.social-link-circle-icon {
  max-height: 18px;
}

.contact-grid {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-rows: auto;
  grid-template-columns: 2fr 1fr;
  grid-auto-columns: 1fr;
  align-self: stretch;
  display: grid;
}

.contact-grid.square-left {
  grid-template-columns: 1fr 2fr;
}

.contact-card {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: var(--primary-3b);
  border-radius: 18px;
  flex-direction: column;
  justify-content: space-between;
  padding: 36px;
  display: flex;
}

.contact-card.single-location {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: var(--gray-light);
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.contact-card.single-location.bg-primary-2b {
  background-color: var(--primary-2b);
}

.contact-card.single-location.bg-primary-1b {
  background-color: var(--primary-1a);
}

.contact-card-methods {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.legal-section {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.legal-subtitle {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.legal-title {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.legal-footer {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  text-align: center;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.container---xl {
  width: 100%;
  max-width: 1142px;
}

.container---xl.align-center {
  margin-left: auto;
  margin-right: auto;
}

.faq-section {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.faq-title {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.faq-wrapper {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  background-color: var(--border-dark);
  border-radius: 18px;
  flex-direction: column;
  padding: 18px;
  display: flex;
}

.accordion-item {
  background-color: var(--white);
  border-radius: 9px;
  padding: 0;
}

.accordtion-title {
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  display: flex;
}

.accordion-circle {
  background-color: var(--border-dark);
  border-radius: 50%;
  flex: none;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  display: flex;
}

.accordion-content {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-flow: row;
  padding: 0 24px 24px;
  display: flex;
}

.comparison-table-row {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  justify-items: center;
  padding: 18px;
  display: grid;
}

.comparison-table-row.bg-white {
  border-radius: 9px;
}

.comparison-table-row.three-columns {
  grid-template-columns: 1fr 1fr 1fr;
}

.comparison-table-row.two-columns {
  grid-template-columns: 1fr 1fr;
}

.comparison-table-section {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.comparison-table-description {
  font-variation-settings: 'wght' 575;
  text-align: left;
}

.checkmark-hidden {
  opacity: 0;
}

.comparison-table {
  position: relative;
}

.comparison-table-sections {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  display: flex;
}

.boxed-image-section {
  padding-bottom: 72px;
  padding-left: 0;
  display: flex;
  position: relative;
}

.boxed-image-section.bg-primary-2b {
  background-color: var(--primary-1a);
}

.boxed-image-section.bg-primary-3b {
  background-color: var(--border-dark);
}

.boxed-image-section.increased-bottom-padding {
  border-top-right-radius: 30px;
}

.boxed-image-section-image {
  aspect-ratio: auto;
  max-width: 547px;
  width: 100%;
  /* position: relative; */
  /* top: 50px; */
  /* left: -100px; */
}

.boxed-image-section-image.landing-image {
  max-width: 500px;
}

.boxed-image-section-content-wrapper {
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 48px;
  display: flex;
  transform: translate(0, 36px);
}

.boxed-image-section-content-wrapper.req-fix {
  width: 60%;
}

.boxed-image-section-quote {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.app-store-reviews-horizontal {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  text-align: left;
  align-items: center;
  display: flex;
}

.app-store-badges {
  align-items: center;
  display: flex;
}

.app-store-reviews-text {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}

.dual-plan-grid {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.pricing-plan-2 {
  border-radius: 18px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
}

.pricing-plan-2-header {
  background-color: var(--primary-2b);
  color: var(--primary-2c);
  padding: 36px 24px;
  position: relative;
}

.pricing-plan-2-header.bg-primary-1b {
  background-color: var(--primary-1b);
  color: var(--primary-1c);
}

.pricing-plan-2-body {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  background-color: var(--white);
  flex-direction: column;
  padding: 24px;
  display: flex;
  position: static;
}

.pricing-plan-2-text {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.pricing-plan-2-price-wrapper {
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  align-items: center;
  display: flex;
}

.pricing-plan-2-price-terms {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}

.plan-cluster {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  display: flex;
}

.pricing-plan-3 {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  text-align: center;
  background-color: #ffcb05;
  border-radius: 18px;
  flex-direction: column;
  align-items: center;
  padding: 72px;
  font-weight: 700;
  display: flex;
}

.pricing-plan-3-price-wrapper {
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  align-items: center;
  display: flex;
}

.pricing-plan-3-price-terms {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}

.quote-box-author-with-avatar {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: row;
  align-items: center;
  display: flex;
}

.reviews-title {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.reviews-grid {
  grid-column-gap: 18px;
  grid-row-gap: 96px;
  grid-template-rows: auto;
  grid-template-columns: 1fr minmax(200px, 1fr) 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.review-box {
  grid-column-gap: 60px;
  grid-row-gap: 60px;
  background-color: var(--white);
  text-align: center;
  border-radius: 18px;
  flex-direction: column;
  align-items: center;
  padding: 36px;
  display: flex;
}

.review-box-image {
  width: auto;
  max-height: 120px;
  margin-top: -96px;
}

.review-box-text-stars {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  flex: 1;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.cta-intro {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  text-align: center;
  flex-direction: column;
  display: flex;
}

.cta-intro-title {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.icons-title-row {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.icons-title-icons {
  align-items: center;
  max-height: 82px;
  display: flex;
}

.horizontal-buttons {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.coming-soon-page-wrapper {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  text-align: center;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  padding: 48px;
  display: flex;
}

.coming-soon-content-wrapper {
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: stretch;
  width: 100%;
  max-width: 547px;
  display: flex;
}

.coming-soon-contents {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.coming-soon-box {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  background-color: var(--white);
  text-align: center;
  border-radius: 18px;
  flex-direction: column;
  align-self: stretch;
  padding: 48px 24px;
  display: flex;
}

.coming-soon-footer {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.coming-soon-logo {
  width: auto;
  min-height: 32px;
}

.widgets {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-flow: wrap;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.widget {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  background-color: var(--white);
  color: var(--gray-dark);
  text-align: left;
  border-radius: 18px;
  flex-direction: column;
  padding: 24px;
  display: flex;
  overflow: hidden;
}

.widget.savings {
  flex: none;
  width: 292px;
}

.widget.savings.overlay-image {
  position: absolute;
  bottom: 36px;
  right: 48px;
}

.widget.composite {
  flex-direction: column;
  max-width: 428px;
  padding: 0;
  display: flex;
  position: relative;
}

.widget.composite.square {
  aspect-ratio: 1;
  width: 100%;
  max-width: none;
  height: auto;
}

.widget.pay-widget {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  flex: none;
  align-items: flex-start;
  width: 231px;
  padding: 18px;
}

.widget.transaction {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  background-color: var(--gray-light);
  width: 243px;
  padding: 12px 6px 6px;
}

.widget.ai-notification-widget {
  text-align: center;
  align-items: center;
  max-width: 324px;
}

.widget.credit-card-widget {
  aspect-ratio: 3 / 2;
  background-color: var(--primary-2b);
  color: var(--primary-2c);
  flex: none;
  justify-content: space-between;
  width: 467px;
  height: auto;
}

.widget.savings-forecast {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  width: 100%;
  max-width: 372px;
}

.widget.savings-goals {
  grid-column-gap: 30px;
  grid-row-gap: 30px;
  color: var(--gray-dark);
  width: 100%;
  max-width: 372px;
}

.widget.savings-planner {
  width: 100%;
  max-width: 379px;
}

.widget-selector {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  align-items: center;
  display: flex;
}

.widget-selector-toggle {
  background-color: var(--primary-3b);
  color: var(--primary-1c);
  text-align: center;
  border-radius: 6px;
  flex: 1;
  padding: 6px;
  font-family: inherit;
  font-size: 14px;
}

.widget-selector-toggle.bg-primary-1c {
  background-color: var(--gray-dark);
  color: var(--white);
}

.widget-savings-input-box {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  align-items: center;
  display: flex;
}

.widget-rows {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}

.widget-text-pair {
  justify-content: space-between;
  align-self: stretch;
  align-items: center;
  display: flex;
}

.widget-notification {
  background-color: var(--white);
  border-radius: 18px;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  display: flex;
  position: absolute;
  bottom: 9px;
  left: 9px;
  right: 9px;
}

.widget-icon-circle {
  background-color: var(--primary-1a);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  display: flex;
}

.widget-icon-circle-icon {
  width: auto;
  max-height: 24px;
}

.widget-notification-title {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  display: flex;
}

.widget-message-wrapper {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  text-align: left;
  align-items: flex-end;
  max-width: 463px;
  display: flex;
}

.widget-message-wrapper.overlapping-image {
  position: absolute;
  top: 36px;
}

.widget-message-avatar {
  max-height: 72px;
}

.widget-message {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  background-color: var(--white);
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  flex-direction: column;
  padding: 18px;
  display: flex;
}

.widget-message-from {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  align-items: center;
  display: flex;
}

.widget-pay-text {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}

.widget-transaction-title {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  justify-content: flex-start;
  align-items: center;
  padding-left: 12px;
  padding-right: 12px;
  display: flex;
}

.widget-transaction-recipient {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  display: flex;
}

.widget-transaction-author {
  max-height: 48px;
}

.widget-credit-card-chip {
  margin-top: 36px;
  margin-left: 36px;
  display: block;
}

.widget-pay-avatars {
  align-items: center;
  display: flex;
}

.widget-pay-avatar {
  width: auto;
  max-height: 36px;
  margin-right: -6px;
  display: block;
}

.widget-savings-title {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}

.widget-savings-amount-wrapper {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  align-items: center;
  display: flex;
}

.widget-savings-amount-indicator {
  grid-column-gap: 2px;
  grid-row-gap: 2px;
  align-items: center;
  display: flex;
}

.text-primary-2c {
  color: var(--primary-2c);
}

.widget-bar-graph-wrapper {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  display: flex;
}

.widget-bar-graph-item {
  background-color: var(--primary-1b);
  border-radius: 6px;
  width: 100%;
  height: 20px;
}

.widget-bar-graph-item.item-1 {
  height: 25px;
}

.widget-bar-graph-item.item-2 {
  height: 35px;
}

.widget-bar-graph-item.item-3 {
  height: 45px;
}

.widget-bar-graph-item.item-4 {
  height: 55px;
}

.widget-bar-graph-item.item-5 {
  height: 65px;
}

.widget-goal-wrapper {
  grid-column-gap: 6px;
  grid-row-gap: 6px;
  flex-direction: column;
  display: flex;
}

.progress-wrapper {
  background-color: var(--primary-2a);
  border-radius: 200px;
  width: 100%;
  height: 12px;
  position: relative;
  overflow: hidden;
}

.progress-wrapper.bg-primary-3a {
  background-color: var(--primary-3a);
}

.progress-bar {
  background-color: var(--primary-2b);
  border-radius: 200px;
  width: 64%;
  height: 100%;
  position: absolute;
}

.progress-bar.bg-primary-3b {
  background-color: var(--primary-3b);
  width: 39%;
}

.widget-planner-title {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  display: flex;
}

.widget-planner-avatar {
  width: auto;
  max-height: 60px;
}

.savings-planner-wrapper {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  display: flex;
  position: relative;
}

.savings-planner-item {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  align-items: center;
  display: flex;
}

.savings-planner-circle {
  background-color: var(--primary-2c);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 28px;
  height: 28px;
  display: flex;
  position: relative;
}

.savings-planner-circle-white {
  background-color: var(--white);
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.savings-planner-line {
  background-color: var(--primary-2c);
  width: 2px;
  height: 100%;
  position: absolute;
  left: 13.5px;
}

.hero-section-1 {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.widgets-cluster {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  justify-content: center;
  align-self: stretch;
  align-items: center;
  display: flex;
  position: relative;
}

.product-feature-box {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  color: var(--white);
  text-align: center;
  background-color: #ffcb05;
  border-radius: 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 520px;
  padding: 48px;
  display: flex;
}

.product-feature-box.bg-primary-2c {
  background-color: var(--primary-3b);
  text-align: center;
}

.product-feature-box.bg-primary-2b {
  background-color: var(--primary-1a);
  color: var(--gray-dark);
}

.product-feature-box.bg-primary-3b {
  background-color: var(--gray-light);
  color: var(--gray-dark);
}

.product-feature-box-text {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  align-items: center;
  display: flex;
}

.product-feature-quote {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.product-feature-quote-author {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  align-items: flex-end;
  display: flex;
}

.product-feature-quote-image {
  width: auto;
  max-height: 72px;
}

.section.protection-plan {
  height: fit-content;
  padding-bottom: 10vh;
}

.small-features-grid {
  width: 90%;
  margin: auto;
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.small-feature-box {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  background-color: var(--white);
  border-radius: 18px;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;
  display: flex;
}

.small-feature-box.reduced-padding {
  padding: 19px;
}

.multipurpose-text-section {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.multipurpose-title {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  display: flex;
}

.empty-cart {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
}

.hero-cta-contents {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.product-features-section {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.product-features-grid {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  display: grid;
  margin-top: -10vh;
}

.product-features-column {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  display: flex;
}

.product-features-column.offset {
  margin-top: 144px;
}

.small-feature-text {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  display: flex;
}

.horizontal-icon-features {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  align-self: stretch;
  font-family: inherit;
  display: flex;
}

.horizontal-icon-feature {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  align-items: center;
  display: flex;
}

.scrolling-quote-box-wrapper {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-wrap: nowrap;
  align-items: flex-end;
  width: 100%;
  display: flex;
  position: relative;
  overflow: hidden;
}

.scrolling-quote-box {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  background-color: var(--white);
  border-radius: 18px;
  flex-direction: column;
  flex: none;
  width: 100%;
  max-width: 428px;
  padding: 36px;
  display: flex;
}

.scrolling-quote-box.bg-primary-3b {
  background-color: var(--primary-3b);
}

.scrolling-quote-box.bg-primary-2b {
  background-color: var(--primary-2b);
}

.scrolling-quote-box.bg-primary-1a {
  background-color: var(--primary-1a);
}

.scrolling-quote-boxes {
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  text-align: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-end;
  font-weight: 400;
  display: flex;
}

.scrolling-quote-box-scrim {
  background-image: linear-gradient(
    270deg,
    var(--primary-3a),
    rgba(254, 245, 236, 0) 10%,
    rgba(254, 245, 236, 0) 90%,
    var(--primary-3a)
  );
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.product-cluster-section {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.product-cluster {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
}

.product-cluster-column {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  align-items: flex-end;
  display: flex;
}

.chat-widget-composite {
  border-radius: 18px;
  justify-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.widget-scrim {
  opacity: 0.7;
  pointer-events: none;
  background-image: linear-gradient(#000, #000 0%, rgba(0, 0, 0, 0) 101%);
  height: 60%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.product-features-grid-section {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.product-grid-thirds {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.product-grid-thirds.request-repair {
  display: flex;
  justify-content: space-evenly;
}

.product-feature-item {
  flex: 0 0 calc(33.3333% - 20px);
  margin: 10px;
}

.product-feature-widget-title {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.widget-square-box {
  aspect-ratio: 1;
  background-color: var(--gray-light);
  border-radius: 18px;
  width: 400px;
  height: fit-content;
  padding: 36px;
}

.widget-square-box.request-repair {
  width: 400px;
  height: 400px;
  padding: 36px;
  justify-content: center;
  align-items: center;
  display: flex;
}

.widget-square-box.bg-primary-1c {
  background-color: var(--primary-1c);
}

.feature-grid-section {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 2fr;
  grid-auto-columns: 1fr;
  align-items: flex-start;
  display: grid;
}

.small-features-grid-thirds {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.small-feature-box-icon-title {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.form-with-terms {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  display: flex;
}

.boxed-image-section-subscribe {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  display: flex;
}

.boxed-image-section-subscribe.fix .display-heading {
  font-size: 52px;
}

.dropdown-link-with-badge {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  align-items: center;
  transition: opacity 0.2s;
  display: flex;
}

.dropdown-link-with-badge:hover {
  opacity: 0.7;
}

.search-form {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  margin-bottom: 0;
  display: flex;
}

.search-title {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.search-wrapper {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.search-result-list {
  grid-column-gap: 42px;
  grid-row-gap: 42px;
  flex-direction: column;
  display: flex;
}

.style-guide-title {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  display: flex;
}

.widgets-wrapper,
.home-hero-section {
  grid-column-gap: 72px;
  grid-row-gap: 72px;
  flex-direction: column;
  display: flex;
}

.screenshots-cluster-section {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  background-color: var(--primary-2b);
  border-radius: 36px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  max-height: 650px;
  padding: 36px 72px 0;
  display: grid;
  overflow: hidden;
}

.screenshots-cluster-column {
  grid-column-gap: 18px;
  grid-row-gap: 18px;
  flex-direction: column;
  display: flex;
}

.screenshot-cluster-image {
  border-radius: 9px;
}

.template-feature-box {
  aspect-ratio: 1;
  background-color: var(--border-dark);
  border-radius: 18px;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
}

.template-feature-box.widgets {
  flex-flow: column;
}

.template-feature-box.align-middle {
  align-items: center;
}

.feature-box-screenshot {
  width: 100%;
  max-width: 300px;
  position: relative;
  top: 48px;
}

.feature-box-sections {
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  display: flex;
}

.feature-box-widgets {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex: 0 auto;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  display: flex;
}

.feature-box-widgets.align-top {
  align-items: flex-start;
}

.figma-box {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  background-color: var(--primary-2c);
  color: var(--primary-2a);
  border-radius: 36px;
  flex-direction: row;
  align-items: center;
  padding-top: 72px;
  padding-bottom: 72px;
  padding-left: 72px;
  display: flex;
  overflow: hidden;
}

.figma-cta {
  grid-column-gap: 36px;
  grid-row-gap: 36px;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  display: flex;
}

.figma-screenshot {
  border-radius: 9px;
  max-width: 808px;
  margin-right: -210px;
}

.figma-cta-title {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  flex-direction: column;
  display: flex;
}

.pages-wrapper {
  grid-column-gap: 48px;
  grid-row-gap: 48px;
  flex-direction: column;
  display: flex;
}

.pages-grid {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.screenshot-box {
  aspect-ratio: 1;
  background-color: var(--border-dark);
  border-radius: 18px;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
}

.screenshot-box.align-center {
  align-items: center;
}

.screenshot-link {
  grid-column-gap: 24px;
  grid-row-gap: 24px;
  text-align: center;
  flex-direction: column;
  display: flex;
}

.screenshot-image {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  width: 100%;
  max-width: 300px;
}

.screenshot-image.rounded {
  border-radius: 6px;
}

.page-groups {
  grid-column-gap: 96px;
  grid-row-gap: 96px;
  flex-direction: column;
  display: flex;
}

.widgets-cluster-scrim {
  pointer-events: none;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.dropdown-list-wrapper.w--open {
  background-color: rgba(0, 0, 0, 0);
}

.dropdown-wide-wrapper.w--open {
  background-color: rgba(0, 0, 0, 0);
  left: 0;
}

.text-block {
  color: var(--gray-dark);
}

.pricing-plan-1-copy,
.pricing-plan-1-copy-copy {
  background-color: var(--white);
  border-radius: 18px;
  flex-direction: column;
  height: 100%;
  padding: 9px;
  display: flex;
}

.body,
.body-2,
.body-3,
.body-4,
.body-5,
.body-6,
.body-7,
.body-8 {
  background-color: var(--white);
}

.image,
.image-2,
.image-3 {
  border-radius: 20px;
}

.tabs-menu {
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  text-transform: capitalize;
  border-radius: 0;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: -59px;
  padding-top: 0;
  padding-bottom: 14px;
  display: flex;
}

.text-block-2,
.text-block-3,
.text-block-4,
.text-block-5,
.text-block-6,
.text-block-7 {
  text-align: left;
}

.text-block-8,
.text-block-9,
.text-block-10,
.text-block-11,
.text-block-12,
.text-block-13,
.text-block-14,
.text-block-15 {
  text-align: left;
  font-family: inherit;
}

.bold-text,
.bold-text-2,
.bold-text-3,
.bold-text-4,
.bold-text-5,
.bold-text-6,
.bold-text-7 {
  text-align: left;
}

.text-block-16,
.text-block-17 {
  color: var(--gray-dark);
  font-family: inherit;
}

.text-block-21 {
  text-align: left;
  font-family: inherit;
  font-size: 21px;
  line-height: 1.3em;
  margin-top: -40px;
}

.text-block-22 {
  text-align: left;
  font-family: inherit;
}

.text-block-23,
.text-block-24,
.text-block-25,
.text-block-26,
.text-block-27 {
  text-align: left;
}

.body-9 {
  background-color: var(--white);
}

.body-10,
.body-11 {
  background-color: var(--gray-light);
}

.text-block-28,
.text-block-29,
.text-block-30,
.text-block-31 {
  text-align: left;
}

.body-12 {
  background-color: var(--white);
}

.text-block-32,
.text-block-33 {
  text-align: left;
}

.body-13 {
  background-color: var(--white);
}

.heading,
.text-block-34,
.text-block-35,
.text-block-36,
.text-block-37,
.text-block-38 {
  text-align: left;
}

.text-block-39,
.text-block-40,
.text-block-41,
.text-block-42,
.text-block-43,
.text-block-44,
.text-block-45,
.text-block-46,
.text-block-47 {
  font-family: inherit;
}

.dropdown-wide-2 {
  background-color: #fff;
  transform: translate(0, 29px);
}

.dropdown-wide-2.w--open {
  left: 0;
  transform: translate(0, 29px);
}

.text-block-50 {
  font-family: inherit;
}

.button-text-2 {
  z-index: 2;
  text-align: left;
  font-family: inherit;
  position: relative;
}

.navbar-wrap {
  z-index: 99;
  background-color: #fff;
  font-style: normal;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.additional-nav-link-2 {
  font-family: inherit;
}

.heading-four-2 {
  color: #262b2c;
  text-align: center;
  font-size: 29px;
  font-weight: 700;
  line-height: 1.2em;
}

.container---main-2 {
  text-align: left;
  width: 100%;
  max-width: 1428px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 24px;
  padding-right: 24px;
}

.nav-link-2 {
  color: #262b2c;
  border-radius: 200px;
  padding: 3px 12px;
  transition: background-color 0.2s;
  display: block;
  position: static;
}

.nav-link-2:hover {
  background-color: rgba(38, 43, 44, 0.1);
}

.dropdown-wide-contents-2 {
  border-top: 1px solid rgba(38, 43, 44, 0.1);
  border-bottom: 1px solid rgba(38, 43, 44, 0.1);
  padding: 24px 24px 36px;
}

.nav-menu-2 {
  align-items: center;
  display: flex;
  position: static;
}

.navbar-wrapper-3 {
  z-index: 99;
  background-color: #fff;
  font-style: normal;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.button-effect-2 {
  z-index: 1;
  opacity: 0.05;
  background-color: #03464f;
  border-radius: 200px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 100%;
  right: 100%;
}

.button-effect-2.bg-white {
  background-color: #dbdbdb;
}

.dropdown-toggle-2 {
  grid-column-gap: 3px;
  grid-row-gap: 3px;
  color: #262b2c;
  font-variation-settings: 'wght' 575;
  align-items: center;
  padding: 0;
  display: flex;
}

.button-4 {
  grid-column-gap: 9px;
  grid-row-gap: 9px;
  color: #262b2c;
  font-variation-settings: 'wght' 575;
  text-align: center;
  background-color: #fef5ec;
  border-radius: 200px;
  justify-content: center;
  align-items: center;
  padding: 18px 36px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.button-4.w--current {
  background-color: #e6e9ea;
}

.button-4.small {
  padding: 12px 24px;
}

.button-4.small.bg-gray-b {
  color: #fff;
  background-color: #262b2c;
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 64px;
  }

  h2 {
    font-size: 48px;
  }

  h3 {
    font-size: 36px;
  }

  h4 {
    font-size: 26px;
  }

  .container---main.inside-dropdown {
    padding-left: 0;
    padding-right: 0;
  }

  .template-utility-section {
    grid-row-gap: 56px;
    grid-template-columns: 1fr;
  }

  .search-modal-container {
    padding-left: 18px;
    padding-right: 18px;
  }

  .display-heading {
    font-size: 84px;
  }

  .announcement-text {
    font-size: 14px;
    line-height: 1.4em;
  }

  .menu-button {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0;
    display: block;
  }

  .menu-button.w--open {
    background-color: rgba(0, 0, 0, 0);
  }

  .navbar-row {
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .heading-one {
    font-size: 64px;
  }

  .heading-two {
    font-size: 48px;
  }

  .heading-two.feature-grid-heading {
    text-align: center;
  }

  .heading-three {
    font-size: 36px;
  }

  .heading-three.about-intro-text {
    font-size: 29px;
  }

  .heading-four {
    font-size: 26px;
  }

  .heading-four.dropdown-heading {
    font-size: 21px;
  }

  .nav-link {
    border-bottom: 1px solid var(--border-dark);
    font-variation-settings: 'wght' 700, 'wdth' 75;
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0 0 18px;
    font-size: 26px;
  }

  .nav-link:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .dropdown-toggle {
    font-variation-settings: 'wght' 700, 'wdth' 75;
    justify-content: space-between;
  }

  .additional-nav-link,
  .nav-cart-inside-nav-menu {
    display: none;
  }

  .nav-cart-mobile {
    display: block;
  }

  .nav-menu {
    z-index: 100;
    background-color: var(--primary-3a);
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: scroll;
  }

  .mobile-logo,
  .mobile-logo.w--current {
    margin-bottom: 48px;
    display: block;
  }

  .nav-links-wrapper {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    flex-direction: column;
    align-items: stretch;
    padding: 36px;
    display: flex;
  }

  .close-mobile-menu {
    pointer-events: auto;
  }

  .dropdown-wide.w--open {
    padding-top: 24px;
    position: static;
    transform: none;
  }

  .dropdown-wide-contents {
    border-top-style: none;
    border-bottom-style: none;
    padding: 0 0 24px;
  }

  .dropdown-wide-grid {
    grid-template-columns: 1fr;
  }

  .dropdown-promo {
    display: none;
  }

  .dropdown-menu-link {
    font-size: 16px;
  }

  .footer-grid {
    grid-template-columns: 1fr 1fr;
  }

  .dropdown-list {
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0);
    padding-top: 12px;
    transform: none;
  }

  .dropdown-list.w--open {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0);
    flex-direction: column;
    align-items: flex-start;
    padding-top: 18px;
    padding-bottom: 12px;
    position: relative;
    transform: translate(0);
  }

  .dropdown-link {
    margin: 12px 0;
    padding: 0;
    font-size: 16px;
  }

  .search {
    max-width: none;
  }

  .plan-grid {
    grid-template-columns: 1fr;
  }

  .pricing-plan-1-body {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
  }

  .product-details-box {
    grid-template-columns: 1fr;
  }

  .blog-grid-thirds {
    grid-template-columns: 1fr 1fr;
  }

  .blog-image-link {
    border-radius: 12px;
  }

  .subscribe-section {
    flex-direction: column;
  }

  .image-box {
    aspect-ratio: 3 / 2;
  }

  .grid-halves {
    grid-template-columns: 1fr;
  }

  .rounded-image.about-cluster-image {
    display: none;
  }

  .rounded-image.location-image {
    aspect-ratio: 2.39;
    object-fit: cover;
  }

  .benefits-grid {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    grid-template-columns: 1fr 1fr;
  }

  .icon-circle.overlap-next-icon {
    padding: 12px;
  }

  .career-benefits-cluster {
    flex-direction: column;
    align-items: center;
  }

  .career-benefits-boxes {
    align-items: center;
  }

  .career-benefits-image {
    aspect-ratio: 1;
  }

  .about-intro-text {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .about-cluster {
    grid-template-columns: 1fr 1fr;
  }

  .blog-summary-grid {
    grid-row-gap: 48px;
    grid-template-columns: 1fr 1fr;
  }

  .blog-summary-grid.thirds {
    grid-template-columns: 1fr;
  }

  .large-cta-box-text.align-left {
    align-items: center;
  }

  .icon-circle-image {
    width: auto;
    max-height: 42px;
  }

  .contact-methods-section {
    grid-template-columns: 1fr 1fr;
  }

  .contact-grid {
    grid-template-columns: 1fr;
  }

  .contact-grid.square-left {
    flex-direction: column;
    display: flex;
  }

  .contact-card.single-location {
    order: 0;
    display: flex;
  }

  .contact-card-methods {
    width: 100%;
  }

  .legal-section {
    grid-column-gap: 60px;
    grid-row-gap: 60px;
  }

  .boxed-image-section {
    grid-column-gap: 72px;
    grid-row-gap: 72px;
    flex-direction: column;
    align-items: center;
    padding-top: 72px;
  }

  .boxed-image-section.increased-bottom-padding {
    flex-direction: column;
    padding: 20px 0;
  }

  .boxed-image-section-content-wrapper {
    transform: none;
  }

  .dual-plan-grid {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .plan-cluster {
    flex-direction: column;
  }

  .pricing-plan-3 {
    padding: 54px;
  }

  .reviews-grid {
    grid-template-columns: 1fr 1fr;
  }

  .review-box {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .icons-title-row {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .widget.credit-card-widget {
    width: 400px;
    padding: 18px;
  }

  .widgets-cluster {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    overflow: hidden;
  }

  .product-feature-box {
    min-height: auto;
  }

  .small-features-grid {
    grid-template-columns: 1fr 1fr;
  }

  .small-features-grid.text-only {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .hero-cta-contents {
    text-align: center;
    align-items: start;
  }

  .product-features-grid {
    flex-direction: column;
    align-items: stretch;
    display: flex;
    /* margin-top: 0vh; */
  }

  .product-features-column.offset {
    margin-top: 0;
  }

  .product-cluster {
    flex-direction: column;
    align-items: center;
  }

  .product-cluster-column,
  .product-features-grid-section {
    align-items: center;
  }

  .product-grid-thirds {
    grid-column-gap: 72px;
    grid-row-gap: 72px;
    flex-direction: column;
    max-width: 100%;
    margin: auto;
    display: flex;
  }

  .feature-grid-section {
    flex-direction: column;
    display: flex;
  }

  .small-features-grid-thirds {
    grid-template-columns: 1fr 1fr;
  }

  .boxed-image-section-subscribe {
    text-align: center;
    align-items: center;
  }

  .dropdown-link-with-badge {
    font-size: 16px;
  }

  .home-hero-section {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
  }

  .screenshots-cluster-section {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    border-radius: 18px;
    max-height: 360px;
    padding: 24px 24px 0;
  }

  .screenshots-cluster-column {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .screenshot-cluster-image {
    border-radius: 6px;
  }

  .figma-box {
    grid-column-gap: 72px;
    grid-row-gap: 72px;
    text-align: center;
    flex-direction: column;
    padding-bottom: 36px;
    padding-left: 36px;
    padding-right: 36px;
  }

  .figma-cta {
    align-items: center;
  }

  .figma-screenshot {
    max-width: 100%;
    margin-right: 0;
  }

  .figma-cta-title {
    align-items: center;
  }

  .pages-grid {
    grid-template-columns: 1fr 1fr;
  }

  .screenshot-box {
    border-radius: 12px;
  }

  .screenshot-image {
    max-width: 240px;
  }

  .widgets-cluster-scrim {
    background-image: linear-gradient(
      to right,
      var(--primary-3a),
      rgba(254, 246, 239, 0) 20%,
      rgba(254, 245, 236, 0) 80%,
      var(--primary-3a)
    );
  }

  .dropdown-list-wrapper.w--open,
  .dropdown-wide-wrapper.w--open {
    position: relative;
  }

  .dropdown-wide-2.w--open {
    padding-top: 24px;
    position: static;
    transform: none;
  }

  .additional-nav-link-2 {
    display: none;
  }

  .heading-four-2 {
    font-size: 26px;
  }

  .heading-four-2.dropdown-heading {
    font-size: 21px;
  }

  .container---main-2.inside-dropdown {
    padding-left: 0;
    padding-right: 0;
  }

  .nav-link-2 {
    font-variation-settings: 'wght' 700, 'wdth' 75;
    border-bottom: 1px solid rgba(38, 43, 44, 0.1);
    border-radius: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0 0 18px;
    font-size: 26px;
  }

  .nav-link-2:hover {
    background-color: rgba(0, 0, 0, 0);
  }

  .dropdown-wide-contents-2 {
    border-top-style: none;
    border-bottom-style: none;
    padding: 0 0 24px;
  }

  .nav-menu-2 {
    z-index: 100;
    background-color: #fef5ec;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: scroll;
  }

  .dropdown-toggle-2 {
    font-variation-settings: 'wght' 700, 'wdth' 75;
    justify-content: space-between;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    letter-spacing: 0;
    font-size: 54px;
    line-height: 1em;
  }

  h2 {
    letter-spacing: 0;
    font-size: 42px;
  }

  h3 {
    font-size: 29px;
    line-height: 1.2em;
  }

  h4 {
    font-size: 21px;
  }

  h6 {
    font-size: 16px;
    line-height: 24px;
  }

  .section {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .section.request-section {
    margin-bottom: 2vh;
  }

  .tabs-menu {
    margin-right: 100px;
    margin-top: 5px;
  }

  .section.increased-bottom-padding {
    padding-bottom: 96px;
  }

  .section.first-section {
    padding-top: 42px;
    padding-bottom: 60px;
  }

  .large-text {
    font-size: 18px;
  }

  .display-heading {
    letter-spacing: -0.01em;
    font-size: 64px;
  }

  .navbar-wrapper {
    position: static;
  }

  .navbar-row {
    height: 72px;
  }

  .photography-license-link {
    border-radius: 12px;
  }

  .article blockquote {
    font-size: 32px;
  }

  .article img {
    border-radius: 12px;
  }

  .heading-one {
    letter-spacing: 0;
    font-size: 54px;
    line-height: 1em;
  }

  .heading-two {
    letter-spacing: 0;
    font-size: 42px;
  }

  .heading-two.text-primary-2b.quote-text {
    font-size: 29px;
  }

  .heading-three {
    font-size: 29px;
    line-height: 1.2em;
  }

  .heading-four {
    font-size: 21px;
  }

  .style-guide-swatch {
    height: 90px;
  }

  .nav-right-contents {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .navbar-logo {
    width: auto;
    max-height: 24px;
  }

  .nav-links-wrapper {
    padding: 30px;
  }

  .close-mobile-menu {
    top: 30px;
    right: 30px;
  }

  .footer-cta {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    flex-direction: column;
  }

  .footer-copyright-social {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    text-align: center;
    flex-direction: column;
  }

  .social-links-wrapper.compact {
    flex-wrap: wrap;
  }

  .footer-legal-text.small-text {
    font-size: 13px;
  }

  .footer-logo-image {
    width: auto;
    max-height: 32px;
  }

  .dropdown-list.w--open {
    padding-top: 12px;
  }

  .form-box {
    padding-top: 36px;
    padding-bottom: 36px;
  }

  .account-form-contents {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .user-account-wrapper {
    padding-bottom: 72px;
  }

  .cart-container {
    border-radius: 9px;
  }

  .cart-header {
    padding-bottom: 0;
  }

  .plan-subheading {
    display: none;
  }

  .product-features-price-wrapper {
    padding-left: 12px;
    padding-right: 12px;
  }

  .checkout-form-contents {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .blog-intro {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
  }

  .blog-grid-halves {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .blog-list-item {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .blog-image {
    border-radius: 9px;
  }

  .blog-grid-thirds {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .subscribe-section {
    border-radius: 18px;
    padding: 48px;
  }

  .container---s.product-feature-desription-wrapper {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .subscribe-graphic {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .subscribe-graphic-image {
    border-radius: 12px;
  }

  .author-title-text {
    grid-column-gap: 9px;
    grid-row-gap: 9px;
  }

  .career-list-item {
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    flex-direction: column;
    align-items: flex-start;
    padding: 18px;
  }

  .image-box {
    border-radius: 18px;
  }

  .image-box.bg-gray-dark {
    padding: 24px;
  }

  .career-intro {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .career-text {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .career-benefits {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
  }

  .benefits-grid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .metric-text {
    font-size: 96px;
  }

  .career-benefits-image {
    aspect-ratio: 1;
    max-width: 100%;
  }

  .about-cluster,
  .about-cluster-column {
    grid-column-gap: 9px;
    grid-row-gap: 9px;
  }

  .blog-summary-cta {
    padding: 18px;
  }

  .large-cta-box {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    border-radius: 18px;
    padding: 36px;
  }

  .large-cta-box.even-padding {
    padding-bottom: 72px;
  }

  .contact-card-methods {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .legal-section,
  .faq-section {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
  }

  .comparison-table-row {
    padding: 12px;
    font-size: 16px;
  }

  .comparison-table-row.header {
    background-color: var(--primary-3a);
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }

  .boxed-image-section {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    padding-top: 48px;
    padding-bottom: 0;
  }

  .boxed-image-section.increased-bottom-padding {
    padding-bottom: 0;
    border-top-right-radius: 0;
    flex-direction: column;
  }

  .boxed-image-section-image {
    border-radius: 24px;
    max-width: 100%;
    left: 0;
    top: 0;
  }

  .section.main-section {
    height: fit-content;
  }

  .dual-plan-grid {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    flex-direction: column;
    display: flex;
  }

  .pricing-plan-3 {
    padding: 48px;
  }

  .reviews-grid {
    grid-row-gap: 72px;
    grid-template-columns: 1fr;
  }

  .review-box-image {
    max-height: 96px;
    margin-top: -72px;
  }

  .icons-title-row {
    flex-direction: column-reverse;
  }

  .horizontal-buttons {
    width: 90%;
    margin: auto;
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .widget {
    border-radius: 12px;
    padding: 18px;
    font-size: 14px;
    line-height: 1.4em;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .widget.savings {
    width: 250px;
  }

  .widget.savings.overlay-image {
    bottom: 24px;
    right: 24px;
  }

  .widget.savings.inside-cluster {
    display: flex;
  }

  .widget.credit-card-widget {
    border-radius: 12px;
    width: 330px;
  }

  .widget-rows {
    grid-column-gap: 2px;
    grid-row-gap: 2px;
  }

  .widget-message {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  .widget-credit-card-chip {
    width: auto;
    max-height: 32px;
  }

  .widgets-cluster {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    overflow: hidden;
  }

  .widget-credit-card-logo {
    width: auto;
    max-height: 24px;
  }

  .product-feature-quote {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .small-features-grid {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .small-feature-box {
    border-radius: 12px;
  }

  .scrolling-quote-box {
    border-radius: 12px;
    max-width: 300px;
    padding: 24px;
  }

  .small-features-grid-thirds {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .screenshots-cluster-section {
    border-radius: 12px;
    max-height: 260px;
  }

  .screenshot-cluster-image {
    border-radius: 4px;
  }

  .figma-box {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    border-radius: 18px;
    padding-top: 54px;
  }

  .pages-grid {
    grid-template-columns: 1fr;
  }

  .screenshot-box {
    aspect-ratio: auto;
    padding-top: 24px;
  }

  .widgets-cluster-scrim {
    background-image: linear-gradient(
      to right,
      var(--primary-3a),
      rgba(254, 247, 239, 0) 15%,
      rgba(254, 245, 236, 0) 85%,
      var(--primary-3a)
    );
  }

  .navbar-wrap {
    position: static;
  }

  .heading-four-2 {
    font-size: 21px;
  }

  .navbar-wrapper-3 {
    position: static;
  }
}

@media screen and (max-width: 479px) {
  body {
    font-size: 15px;
  }

  h1 {
    font-size: 42px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 24px;
  }

  h5 {
    font-size: 18px;
    line-height: 28px;
  }

  .section {
    padding-top: 8px;
    padding-bottom: 48px;
  }

  .section.fix-section {
    flex-direction: column-reverse;
    height: fit-content;
    padding-bottom: 20vh;
  }

  .section.fix-section .boxed-image-section-image {
    top: 100px;
    max-width: 100%;
    border-radius: 0;
  }

  .section.increased-bottom-padding {
    padding-bottom: 72px;
  }

  .section.first-section {
    padding-top: 18px;
    padding-bottom: 54px;
  }

  .container---main {
    padding-left: 8px;
    padding-right: 8px;
  }

  .template-utility-section {
    grid-row-gap: 35px;
  }

  .style-guide-colors-wrapper {
    grid-column-gap: 21px;
    grid-row-gap: 21px;
  }

  .style-guide-section-contents {
    grid-row-gap: 21px;
  }

  .large-text {
    text-align: center;
    font-size: 17px;
  }

  .large-text.account-form-intro {
    display: none;
  }

  .small-text {
    font-size: 13px;
  }

  .small-text.text-bold {
    text-align: center;
  }

  .button {
    padding: 15px 24px;
  }

  .button.search-buton {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .button.bg-gray-dark {
    text-align: left;
  }

  .form-input {
    padding: 14px 12px;
    font-size: 15px;
  }

  .announcement-cross {
    width: 36px;
    height: 36px;
    padding: 8px;
  }

  .utility-page-form {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .search-modal-container {
    align-items: center;
  }

  .select-field {
    background-position: 84%;
    padding: 14px 12px;
    font-size: 15px;
  }

  .display-heading {
    text-align: center;
    font-size: 44px;
  }

  .display-heading.text-center {
    text-align: center;
    font-size: 40px;
  }

  .display-heading.product-heading {
    font-size: 48px;
  }

  .display-heading.hero-cta-heading {
    text-align: center;
  }

  .display-heading.text-primary-2b.hero-heading {
    font-size: 48px;
  }

  .display-heading.home-cta-heading {
    font-size: 42px;
  }

  .announcement-text {
    font-size: 13px;
    line-height: 1.4em;
  }

  .menu-button {
    padding-left: 12px;
    padding-right: 0;
  }

  .navbar-row {
    height: 64px;
  }

  .changelog {
    grid-template-columns: 1fr;
  }

  .photography-license-link {
    border-radius: 9px;
  }

  .typography-license-grid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .typography-demo {
    font-size: 72px;
  }

  .iconography-grid {
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
  }

  .article {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .article blockquote {
    font-size: 24px;
  }

  .article img {
    border-radius: 9px;
  }

  .heading-one.text-center {
    font-size: 40px;
    width: 95%;
    line-height: 1.2em;
    padding: 20px 0;
  }

  .heading-one.subscribe-section-heading,
  .heading-two {
    font-size: 36px;
  }

  .heading-two.large-quote-text {
    font-size: 29px;
    line-height: 1.1em;
  }

  .heading-two.feature-grid-heading {
    font-size: 29px;
  }

  .heading-three {
    font-size: 20px;
  }

  .heading-three.about-intro-text {
    font-size: 24px;
  }

  .heading-four {
    text-align: left;
  }

  .heading-four.dropdown-heading,
  .heading-four.subscription-price {
    font-size: 18px;
  }

  .heading-four.comparison-heading,
  .button-text.text-primary-1b {
    text-align: center;
    font-size: 11px;
  }

  .protect-button {
    font-size: 11px;
    padding: 12px 24px;
  }

  .style-guide-description {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .field-label {
    font-size: 13px;
  }

  .style-guide-colors {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    flex-direction: column;
  }

  .nav-link {
    font-size: 21px;
  }

  .nav-cart-button {
    padding-left: 0;
    padding-right: 0;
  }

  .nav-right-contents {
    grid-column-gap: 6px;
    grid-row-gap: 6px;
  }

  .navbar-logo {
    max-width: 200%;
    max-height: 80px;
  }

  .nav-button-wrapper {
    display: none;
  }

  .nav-menu {
    background-color: var(--white);
  }

  .mobile-logo {
    margin-bottom: 24px;
  }

  .nav-links-wrapper {
    padding: 18px;
  }

  .close-mobile-menu {
    top: 18px;
    right: 18px;
  }

  .dropdown-wide-grid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .dropdown-sub-menu {
    grid-column-gap: 9px;
    grid-row-gap: 9px;
  }

  .dropdown-menu-link {
    font-family: inherit;
    font-size: 15px;
  }

  .footer-grid {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    grid-template-columns: 1fr;
  }

  .footer-menu {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .footer-cta {
    align-items: stretch;
  }

  .footer-buttons {
    flex-direction: column;
    align-items: stretch;
  }

  .footer-legal-text.small-text {
    font-size: 12px;
  }

  .footer-logo-link {
    align-self: center;
  }

  .dropdown-link {
    font-size: 15px;
  }

  .search {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    border-radius: 9px;
    flex-direction: column;
    align-items: stretch;
    padding: 12px;
  }

  .search-input {
    text-align: center;
    padding: 12px;
  }

  .account-page-wrapper {
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .form-box {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 9px;
    padding: 0;
  }

  .user-account-section {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .subscriptions-box {
    padding: 36px 18px;
  }

  .subscription {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    flex-direction: column;
  }

  .pricing-plan-1 {
    border-radius: 9px;
  }

  .pricing-plan-1-header {
    grid-column-gap: 6px;
    grid-row-gap: 6px;
    border-radius: 6px;
    padding: 18px 12px;
  }

  .pricing-plan-1-body {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    padding-left: 9px;
    padding-right: 9px;
  }

  .pricing-plan-action-wrapper {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .cart-container {
    border-radius: 0;
  }

  .cart-footer {
    padding-bottom: 18px;
    padding-left: 18px;
    padding-right: 18px;
  }

  .cart-list {
    padding-left: 18px;
    padding-right: 18px;
  }

  .product-details-box {
    border-radius: 9px;
    flex-direction: column;
    min-height: auto;
    padding: 9px;
    display: flex;
  }

  .product-details-title-wrapper {
    border-radius: 9px;
    padding: 18px 12px;
  }

  .product-title {
    grid-column-gap: 6px;
    grid-row-gap: 6px;
  }

  .product-features-price-wrapper {
    padding-left: 4px;
    padding-right: 4px;
  }

  .app-store-rankings-section {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .app-store-ranking.large {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .service-icon-circle.large.bordered {
    border-width: 4px;
    padding: 18px;
  }

  .stars {
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    width: 90%;
    margin: auto;
  }

  .stars img {
    width: 100%;
  }

  .block-content {
    padding-left: 18px;
    padding-right: 18px;
  }

  .block-header {
    padding: 18px;
  }

  .checkout-form-contents {
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }

  .blog-grid-halves,
  .blog-grid-thirds {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    grid-template-columns: 1fr;
  }

  .subscribe-section {
    padding: 36px 18px;
  }

  .subscribe-title {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .subscribe-form {
    flex-direction: column;
  }

  .subscribe-graphic {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    padding: 12px;
  }

  .subscribe-graphic-image {
    border-radius: 9px;
  }

  .blog-post-container {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .blog-post-title-wrapper {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .blog-post-title {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .blog-post-summary-author {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .blog-main-image {
    border-radius: 9px;
  }

  .author-title {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    flex-direction: column;
  }

  .author-title-text {
    align-items: center;
  }

  .career-basis.inside-career {
    font-size: 13px;
  }

  .form-pair {
    grid-template-columns: 1fr;
  }

  .careers-box {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    padding: 24px 18px;
  }

  .careers-list {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .career-list-item {
    grid-column-gap: 4px;
    grid-row-gap: 4px;
    padding: 12px;
  }

  .image-box {
    aspect-ratio: auto;
  }

  .image-box.bg-primary-3c {
    border-radius: 9px;
    padding-left: 24px;
    padding-right: 24px;
  }

  .image-box.bg-gray-dark {
    padding: 18px;
  }

  .image-box-image.blend-mode-normal {
    opacity: 0.3;
  }

  .image-box-contents {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .image-box-contents.quote {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
  }

  .rounded-image {
    border-radius: 9px;
  }

  .rounded-image.location-image {
    aspect-ratio: auto;
  }

  .metric-box {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    border-radius: 9px;
    padding: 18px;
  }

  .metric-box.reduced-padding {
    padding: 18px;
  }

  .metric-text {
    font-size: 84px;
  }

  .career-benefits-image {
    border-radius: 9px;
  }

  .quote-box {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    border-radius: 9px;
    padding: 18px;
  }

  .about-cluster {
    flex-direction: column;
    display: flex;
  }

  .about-cluster-column.third-column {
    flex-direction: column-reverse;
  }

  .large-quote {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .quote-author-image {
    max-height: 60px;
  }

  .values-list {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .values-list-item {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    flex-direction: column;
    display: flex;
  }

  .blog-summary-grid {
    grid-row-gap: 36px;
    grid-template-columns: 1fr;
  }

  .large-cta-box {
    padding-left: 24px;
    padding-right: 24px;
  }

  .large-cta-box.even-padding {
    align-items: stretch;
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .large-cta-box-text {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .contact-methods-section {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    grid-template-columns: 1fr;
  }

  .contact-box,
  .contact-card {
    border-radius: 9px;
    padding: 18px;
  }

  .legal-section {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .legal-title,
  .legal-footer {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .faq-wrapper {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    border-radius: 9px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .accordtion-title {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    padding-left: 18px;
    padding-right: 18px;
  }

  .accordion-content {
    padding-left: 18px;
    padding-right: 18px;
  }

  .comparison-table-row {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .comparison-table-row.three-columns {
    grid-template-columns: 1fr 1fr;
  }

  .comparison-table-row.two-columns {
    justify-content: space-between;
    display: flex;
  }

  .boxed-image-section {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    padding-top: 36px;
  }

  .boxed-image-section-image {
    border-radius: 18px;
  }

  .boxed-image-section-content-wrapper {
    padding-left: 18px;
    padding-right: 18px;
  }

  .container---m {
    padding-right: 0;
    margin: 0;
  }

  .boxed-image-section-quote {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .app-store-reviews-horizontal.stack-on-mobile {
    flex-direction: column;
    margin-inline: auto;
  }

  .app-store-reviews-text.centered-on-mobile {
    align-items: center;
  }

  .dual-plan-grid {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .pricing-plan-2 {
    border-radius: 9px;
  }

  .pricing-plan-2-header {
    padding: 24px 18px;
  }

  .pricing-plan-2-body {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
    padding: 18px;
  }

  .plan-cluster {
    grid-column-gap: 9px;
    grid-row-gap: 9px;
  }

  .pricing-plan-3 {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    border-radius: 9px;
    padding: 24px;
  }

  .reviews-grid {
    grid-row-gap: 54px;
  }

  .review-box {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    border-radius: 9px;
    padding: 24px 18px;
  }

  .review-box-image {
    margin-top: -54px;
  }

  .cta-intro {
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }

  .horizontal-buttons.stack-on-mobile {
    flex-direction: column;
    align-items: stretch;
  }

  .coming-soon-page-wrapper {
    padding: 18px;
  }

  .coming-soon-contents {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .coming-soon-box {
    border-radius: 9px;
    padding: 36px 18px;
  }

  .coming-soon-logo {
    min-height: 24px;
  }

  .widget {
    border-radius: 9px;
  }

  .widget.savings.overlay-image,
  .widget.savings.inside-cluster,
  .widget.pay-widget.inside-cluster {
    display: none;
  }

  .widget.credit-card-widget {
    border-radius: 9px;
    width: 240px;
    padding: 12px;
  }

  .widget.credit-card-widget.inside-cluster {
    width: 100%;
  }

  .widget-message-wrapper.overlapping-image {
    width: 90%;
    max-width: none;
    top: auto;
    bottom: 18px;
  }

  .widget-message-avatar {
    max-height: 48px;
  }

  .widget-message {
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    border-bottom-right-radius: 9px;
  }

  .widget-credit-card-chip {
    max-height: 24px;
    margin-top: 18px;
    margin-left: 18px;
  }

  .widget-bar-graph-wrapper {
    grid-column-gap: 6px;
    grid-row-gap: 6px;
  }

  .widgets-cluster {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    justify-content: center;
  }

  .widget-credit-card-logo {
    max-height: 18px;
  }

  .product-feature-box {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    border-radius: 9px;
    padding: 18px 18px 36px;
  }

  .product-feature-box-text,
  .product-feature-quote-author {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .product-feature-quote-image {
    max-height: 60px;
  }

  .small-features-grid {
    grid-template-columns: 1fr;
  }

  .small-feature-box {
    border-radius: 9px;
  }

  .hero-cta-contents {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
  }

  .product-features-section {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }
  .section.repair-section {
    padding-block: 10px;
  }

  .scrolling-quote-box {
    grid-column-gap: 54px;
    grid-row-gap: 54px;
    border-radius: 9px;
    max-width: 270px;
    padding: 18px;
  }

  .scrolling-quote-boxes {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .product-cluster-section {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .chat-widget-composite {
    border-radius: 9px;
  }

  .widget-scrim {
    top: auto;
    bottom: 0;
    transform: rotate(180deg);
  }

  .product-features-grid-section {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .product-grid-thirds {
    grid-column-gap: 10px;
    grid-row-gap: 28px;
  }

  .product-grid-thirds.request-repair {
    flex-direction: column;
  }

  .product-feature-widget-title {
    flex-direction: column;
  }

  .product-grid-thirds.request-repair {
    flex-direction: column;
  }

  .widget-square-box {
    aspect-ratio: auto;
    border-radius: 9px;
    padding: 18px;
    background-color: transparent;
    width: unset;
  }

  .widget-square-box.request-repair {
    width: 100%;
    height: 150px;
  }

  .feature-grid-section {
    grid-column-gap: 48px;
    grid-row-gap: 48px;
  }

  .small-features-grid-thirds {
    flex-direction: column;
    display: flex;
  }

  .boxed-image-section-subscribe {
    grid-column-gap: 18px;
    grid-row-gap: 18px;
  }

  .dropdown-link-with-badge {
    font-size: 15px;
  }

  .search-form {
    flex-direction: column;
  }

  .search-result-list {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
  }

  .screenshots-cluster-section {
    grid-template-columns: 1fr 1fr;
    padding: 12px 12px 0;
  }

  .screenshots-cluster-column.hide-on-mobile {
    display: none;
  }

  .template-feature-box {
    aspect-ratio: auto;
    border-radius: 9px;
    max-height: 300px;
    padding: 18px;
  }

  .template-feature-box.widgets {
    flex-direction: column;
  }

  .feature-box-screenshot {
    top: 0;
  }

  .feature-box-widgets {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .feature-box-widgets.align-top {
    display: none;
  }

  .figma-box {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    border-radius: 9px;
    padding: 36px 24px 24px;
  }

  .figma-cta {
    grid-column-gap: 24px;
    grid-row-gap: 24px;
    align-items: stretch;
  }

  .pages-grid {
    grid-column-gap: 36px;
    grid-row-gap: 36px;
    flex-direction: column;
    display: flex;
  }

  .screenshot-box {
    border-radius: 9px;
    align-items: flex-start;
    max-height: 200px;
    overflow: hidden;
  }

  .screenshot-link {
    grid-column-gap: 12px;
    grid-row-gap: 12px;
  }

  .widgets-cluster-scrim {
    display: none;
  }

  .pricing-plan-1-copy,
  .pricing-plan-1-copy-copy {
    border-radius: 9px;
  }

  .text-block-9,
  .bold-text-4,
  .text-block-18,
  .text-block-19,
  .text-block-20 {
    text-align: left;
  }

  .text-block-21 {
    text-align: center;
    font-size: 15px;
    margin: 2px;
  }

  .text-block-22,
  .text-block-23,
  .text-block-24,
  .text-block-25,
  .text-block-26,
  .text-block-27 {
    text-align: left;
  }

  .heading-four-2 {
    text-align: center;
  }

  .heading-four-2.dropdown-heading {
    font-family: inherit;
    font-size: 18px;
  }

  .container---main-2 {
    padding-left: 16px;
    padding-right: 16px;
  }

  .nav-link-2 {
    font-size: 21px;
  }

  .nav-menu-2 {
    background-color: #fff;
  }

  .button-4 {
    padding: 15px 24px;
  }
}

#w-node-_39964da8-3045-47d6-292c-3b3e4d7a10ea-df636a8f,
#w-node-_91215528-acc9-924a-2663-2a8ed490a256-df636a8f,
#w-node-f2a5dd17-d24d-1106-e7a8-7255c5052d91-df636a8f,
#w-node-f2a5dd17-d24d-1106-e7a8-7255c5052d96-df636a8f,
#w-node-f2a5dd17-d24d-1106-e7a8-7255c5052d9b-df636a8f,
#w-node-f2a5dd17-d24d-1106-e7a8-7255c5052da0-df636a8f,
#w-node-cfeb3857-bca4-2696-44bc-e64f0eedc34a-df636a8f,
#w-node-_185b503c-0393-bd40-ddd7-aaf7c03a6e2d-55b37658,
#w-node-a18350f2-b1a0-32fe-31bc-517341a02e24-41a02e0f,
#w-node-_8d889b06-6ea0-08a6-e707-0bceb5a5f49c-824c25a7,
#w-node-ab34b991-5791-48b2-e2c0-904e20d095d8-df636ad8,
#w-node-_8aae2444-f0bc-d46a-2b79-3cc7bd82ad1d-df636ad8,
#w-node-e4c8712c-277f-b73a-1cfa-8471e0ff31a2-df636ad8,
#w-node-_827a1021-a5e1-c814-5837-e2968bd6b118-df636b36,
#w-node-_6963ee1e-ad06-9ef8-eebf-19b2cf07c654-df636b36,
#w-node-ae69a495-3318-a4f7-953a-0f0922f832e9-df636b38,
#w-node-eab1f609-0d41-4ff3-248c-ce2ba2f0fa56-df636b51,
#w-node-ee07e04b-e0ba-f82e-e1f7-2e04bd26b87a-df636b5b,
#w-node-_8aae2444-f0bc-d46a-2b79-3cc7bd82ad1d-df636b5b,
#w-node-_5da54210-0956-178a-ae53-efe9ac86b929-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9f-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea4-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac263511-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d640-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b4-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-df636b75 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6d-df636b75,
#w-node-_5da54210-0956-178a-ae53-efe9ac86b929-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9f-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea4-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac263511-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d640-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b4-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-3001be3a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6d-3001be3a,
#w-node-_5da54210-0956-178a-ae53-efe9ac86b929-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9f-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea4-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac263511-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d640-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b4-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-0b66324b {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6d-0b66324b,
#w-node-_5da54210-0956-178a-ae53-efe9ac86b929-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9f-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea4-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac263511-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d640-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b4-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-d113f55f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6d-d113f55f,
#w-node-_5da54210-0956-178a-ae53-efe9ac86b929-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9f-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_0783dd25-35fa-8958-8740-7f322b43bd6f-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_0783dd25-35fa-8958-8740-7f322b43bd71-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea4-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac263511-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d640-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b4-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-8ae13e0a {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6d-8ae13e0a,
#w-node-_5da54210-0956-178a-ae53-efe9ac86b929-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9f-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_3e9824d1-5409-720c-0d7b-ea8e172d68e6-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_3e9824d1-5409-720c-0d7b-ea8e172d68e8-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea4-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac263511-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d640-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b4-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-86d03665 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6d-86d03665,
#w-node-_5da54210-0956-178a-ae53-efe9ac86b929-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9f-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea4-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac263511-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d640-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b4-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-0b67eb1d {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6d-0b67eb1d,
#w-node-_5da54210-0956-178a-ae53-efe9ac86b929-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9f-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea4-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_4063e20a-18d7-e37c-75e5-3428ac263511-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d640-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b4-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
  justify-self: start;
}

#w-node-e567b114-9890-c5f2-1280-e3c88f178e6d-4cdf3bf8 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

@media screen and (max-width: 479px) {
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-df636b75,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-df636b75,
  #w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-df636b75,
  #w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-df636b75,
  #w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-df636b75,
  #w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-df636b75,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-3001be3a,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-3001be3a,
  #w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-3001be3a,
  #w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-3001be3a,
  #w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-3001be3a,
  #w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-3001be3a,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-0b66324b,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-0b66324b,
  #w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-0b66324b,
  #w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-0b66324b,
  #w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-0b66324b,
  #w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-0b66324b,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-d113f55f,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-d113f55f,
  #w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-d113f55f,
  #w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-d113f55f,
  #w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-d113f55f,
  #w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-d113f55f,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-8ae13e0a,
  #w-node-_0783dd25-35fa-8958-8740-7f322b43bd6f-8ae13e0a,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-8ae13e0a,
  #w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-8ae13e0a,
  #w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-8ae13e0a,
  #w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-8ae13e0a,
  #w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-8ae13e0a,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-86d03665,
  #w-node-_3e9824d1-5409-720c-0d7b-ea8e172d68e6-86d03665,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-86d03665,
  #w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-86d03665,
  #w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-86d03665,
  #w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-86d03665,
  #w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-86d03665,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-0b67eb1d,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-0b67eb1d,
  #w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-0b67eb1d,
  #w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-0b67eb1d,
  #w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-0b67eb1d,
  #w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-0b67eb1d,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201e9d-4cdf3bf8,
  #w-node-c118fcc0-3eb6-4f9c-4a1c-00834c201ea2-4cdf3bf8,
  #w-node-_4063e20a-18d7-e37c-75e5-3428ac26350f-4cdf3bf8,
  #w-node-_6f2cf2d0-22e8-167e-2cac-ca96d284d63e-4cdf3bf8,
  #w-node-_288cbcf2-8611-5d54-4b2a-478a612db9b2-4cdf3bf8,
  #w-node-e567b114-9890-c5f2-1280-e3c88f178e6b-4cdf3bf8 {
    grid-column: span 2 / span 2;
    justify-self: center;
  }
}

@font-face {
  font-family: 'Mtnbrightersans';
  src: url('/fonts/MTNBrighterSans-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Mtnbrightersans';
  src: url('/fonts/MTNBrighterSans-ExtraBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@media (min-width: 992px) {
  html.w-mod-js:not(.w-mod-ix)
    [data-w-id='1c89847e-8e19-aad9-db7c-4ea59a8594ee'] {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0, 0);
    transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0, 0);
  }

  html.w-mod-js:not(.w-mod-ix)
    [data-w-id='c9a3038f-81c6-96c5-9bf5-ee6d59d674bc'] {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0, 0);
    transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0, 0);
  }

  html.w-mod-js:not(.w-mod-ix)
    [data-w-id='ae276f61-0e89-76e5-8e4e-104e25a3514c'] {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0, 0);
    transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0, 0);
  }

  html.w-mod-js:not(.w-mod-ix)
    [data-w-id='cfeb3857-bca4-2696-44bc-e64f0eedc347'] {
    opacity: 0;
    -webkit-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -moz-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0)
      rotateY(0) rotateZ(0) skew(0, 0);
    -ms-transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0, 0);
    transform: translate3d(0, 10%, 0) scale3d(1, 1, 1) rotateX(0) rotateY(0)
      rotateZ(0) skew(0, 0);
  }
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
