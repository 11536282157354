.react-datepicker-wrapper {
  width: 100%;
  font-size: 16px !important;
}

.datepicker-container.isError input {
  border-color: #d14343 !important;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 50px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 0.875rem !important;
  font-weight: 400;
  color: #000;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(55 65 81 / 0.1);
  background-color: #fff;
  border-radius: 10px;

  outline: none;
  line-height: 20px;
  letter-spacing: -0.05px;
  text-decoration: none;
  transition: 0.5s ease all;
}

.react-datepicker__input-container input::placeholder {
  color: var(--black-lighter-color) !important;
}

.react-datepicker__input-container input:focus {
  z-index: 2;
  box-shadow: none !important;
  border-color: blue;
  /* box-shadow: 0 0 0 2px #d6e0ff; */
}

.react-datepicker {
  font-size: 12px;
  color: #000;
  background-color: #fff;
  font-family: inherit;
  /* 'ProximaNova', 'SF UI Text', -apple-system, BlinkMacSystemFont,
    'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif; */
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 14px !important;
}

li.react-datepicker__time-list-item {
  height: unset !important;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
